import { JsClientSideAnalyticsEventFirer } from '@airtasker/kmp-feature-management';
import { trackExperimentViewed } from '@oneflare/web-analytics';

import { OneflareAnalytics } from 'lib/analytics/oneflareAnalytics';

class OneflareJsClientSideAnalyticsEventFirer implements JsClientSideAnalyticsEventFirer {
  constructor(private tracker: OneflareAnalytics) {
    this.tracker = tracker;
  }

  fireExperimentViewedEvent(experimentId: string, variationId: string): void {
    trackExperimentViewed(this.tracker,
      'OneflareJsClientSideAnalyticsEventFirer',
      {
      experimentId,
      variationId
    });
  }
}

export { OneflareJsClientSideAnalyticsEventFirer };
