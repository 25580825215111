import { createContext } from 'react';

import { globalAlertContainer } from 'lib/alerts/globalAlertContainer';

interface PageContextModel {
  AlertContainer: typeof globalAlertContainer;
}

const PageContext = createContext<PageContextModel>({
  AlertContainer: globalAlertContainer
});

export { PageContext, type PageContextModel };
