import {
  ClientSideFeatureManagement,
  ClientSideFeatureManagementConfig,
  Environment,
  Experiment,
  ExperimentParameter,
  JsClientSideAnalyticsEventFirerAdapter,
  JsClientPlatformSpecificConfig,
  JsClientSideStatsigSdkWrapperAdapter,
  SimpleNamedFeature,
  getClientSidePlatformFeatureManagement
} from '@airtasker/kmp-feature-management';

import { OneflareAnalytics } from 'lib/analytics/oneflareAnalytics';

import { FeatureManager, UserContext } from './featureManager';
import { OneflareJsClientSideAnalyticsEventFirer } from './oneflareJsClientSideAnalyticsEventFirer';
import { StatsigSdkWrapperClient } from './statsigSdkWrapperClient';
import { convertUserContextToFeatureManagementUser } from './utils';

class OneflareFeatureManagerClient implements FeatureManager {
  private featureManagement: ClientSideFeatureManagement;

  constructor(
    apiKey: string,
    environment: Environment,
    isTesting: boolean,
    tracker: OneflareAnalytics,
    userContext: UserContext
  ) {
    const statsigSdkWrapper = new StatsigSdkWrapperClient(apiKey, isTesting);
    const config = new ClientSideFeatureManagementConfig(
      convertUserContextToFeatureManagementUser(userContext),
      environment,
      new JsClientPlatformSpecificConfig(
        new JsClientSideStatsigSdkWrapperAdapter(statsigSdkWrapper)
      ),
      new JsClientSideAnalyticsEventFirerAdapter(
        new OneflareJsClientSideAnalyticsEventFirer(tracker)
      )
    );
    this.featureManagement = getClientSidePlatformFeatureManagement(config);
  }

  getFeatureVariableBoolean(
    experimentName: string,
    variableName: string,
    defaultValue: boolean
  ): boolean {
    const result = this.featureManagement.getExperimentParameterBoolean(
      new ExperimentParameter<boolean>(
        new Experiment(experimentName),
        variableName,
        defaultValue
      )
    );
    return result.result;
  }

  getFeatureVariableInteger(
    experimentName: string,
    variableName: string,
    defaultValue: number
  ): number {
    const result = this.featureManagement.getExperimentParameterInteger(
      new ExperimentParameter<number>(
        new Experiment(experimentName),
        variableName,
        defaultValue
      )
    );
    return result.result;
  }

  getFeatureVariableString(
    experimentName: string,
    variableName: string,
    defaultValue: string | null
  ): string | null {
    const result = this.featureManagement.getExperimentParameterString(
      new ExperimentParameter<string | null>(
        new Experiment(experimentName),
        variableName,
        defaultValue
      )
    );
    if (result.result === undefined) {
      return null;
    }
    return result.result;
  }

  isFeatureEnabled(featureKey: string): boolean {
    return this.featureManagement.isFeatureEnabled(
      new SimpleNamedFeature(featureKey)
    );
  }

  isSdkInitialised(): boolean {
    return this.featureManagement.isSdkInitialised();
  }
}

export { OneflareFeatureManagerClient };
