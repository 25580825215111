import { BehaviorSubject } from 'rxjs';

/**
 * An object that represents either a logged in or a logged out user; the current user of the request.
 */
type UserContext = {
  /**
   * Whether the user is logged in.
   **/
  isAuthenticated: boolean;
  /**
   * If the user is logged in, this contains the Oneflare user ID.
   * If the user is logged out, this contains the Oneflare anonymous user ID.
   **/
  userId: string;
  /**
   * The region of the user.
   */
  regionCode: string;
};

interface FeatureManager {
  getFeatureVariableBoolean(
    experimentName: string,
    variableName: string,
    defaultValue: boolean,
  ): boolean;

  getFeatureVariableInteger(
    experimentName: string,
    variableName: string,
    defaultValue: number,
  ): number;

  getFeatureVariableString(
    experimentName: string,
    variableName: string,
    defaultValue: string | null
  ): string | null;

  isFeatureEnabled(featureKey: string, userContext?: UserContext): boolean;

  isSdkInitialised(): boolean;
}

const feature: BehaviorSubject<FeatureManager> = new BehaviorSubject(null);

const setClientFeatureManager = (featureManager: FeatureManager) => {
  feature.next(featureManager);
};

const getClientFeatureManager = () => {
  return feature;
};

export { setClientFeatureManager, getClientFeatureManager };
export type { FeatureManager, UserContext };
