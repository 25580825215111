import device from 'device';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import compact from 'lodash/compact';
import { DateTime } from 'luxon';
import queryString from 'query-string';

import type {
  AddressProps,
  StringifyURLParamsProps,
  GenerateSuburbLinksProps,
  FormatPhoneNumberReturnType,
  GenerateSuburbLinksReturnType
} from 'types/oneflare.com.au/helpers';
import type { ICurrentUser } from 'types/oneflare.com.au/user';

import { JFV3_PATHS_AND_CATEGORIES } from './constants';

/*
* helper to stringify url params
*/
export const stringifyUrlParams = ({ url, params }: StringifyURLParamsProps) => {
  const stringified = queryString.stringify(params);
  return stringified.length ? (`${url}?${stringified}`) : url;
};

export const titleize = (word = '') => word.replace(/^\w/, (c) => c.toUpperCase());

export const titleizeString = (string = '') => {
  return string.toLowerCase().split(' ').map((val) => titleize(val)).join(' ');
};

export const truncate = (string: string, length: number) => {
  return string.length > length ? `${string.substring(0, length)}...` : string;
};

/*
 * Moment.js is a peer dependency of react-dates
 * MultiSelectDatePicker.jsx returns moment object
 * Safe parsing to native dates for luxon
 */
export const toJsDate = (date: string) => {
  return new Date(date);
};

export const friendlyDate = (dateString: string, format = 'dd LLL yyyy') => {
  const jsDate = toJsDate(dateString);
  return DateTime.fromJSDate(jsDate).toFormat(format);
};

export const hasSameDate = (date: string, unit = 'day') => {
  const jsDate = toJsDate(date);
  return DateTime.now().hasSame(jsDate, unit);
};

export const compareDate = ((postedAt: string) => {
  const dateOne = new Date();
  const dateTwo = new Date(String(postedAt));
  const dateOneUTC = Date.UTC(dateOne.getFullYear(), dateOne.getMonth(), dateOne.getDate());
  const dateTwoUTC = Date.UTC(dateTwo.getFullYear(), dateTwo.getMonth(), dateTwo.getDate());
  const difference = (dateOneUTC - dateTwoUTC) / (1000 * 60 * 60 * 24);
  return difference;
});

/**
 * @param  {String} a
 * @param  {String} b
 * @returns {Boolean} Returns the boolean to indicate if the current ENV is a or b.
 */
export const isENV = (a: string, b: string): boolean => {
  return process.env.NODE_ENV === a || process.env.NODE_ENV === b;
};

export const slugHelper = (slug: string) => {
  if (!slug) return '';
  return slug
    .replace(/\w+/g, (s) => s && s[0].toLowerCase() + s.slice(1).toLowerCase())
    .replace(/\s+/g, '-')
    .replace(/'/g, '_');
};

export const extractPostcode = (locationString: string) => {
  if (!locationString) return undefined;
  const postcodePattern = /\b\d{4}\b/;
  const match = locationString.match(postcodePattern);
  return match ? match[0] : undefined;
};

export const formatPhoneNumber = (phone = '', options = { truncate: false }): FormatPhoneNumberReturnType => {
  const phoneNumber = parsePhoneNumberFromString(phone, 'AU');
  let phoneFormatted = phoneNumber.formatNational();

  switch (phoneFormatted.length) {
    case 6: // 13 29 59 phone number support
      phoneFormatted = options.truncate ? `${phoneFormatted.substring(0, 4)}...` : `${phoneFormatted.substring(0, 2)} ${phoneFormatted.substring(2, 4)} ${phoneFormatted.substring(4, 6)}`;
      break;
    case 8: // 9834 8998 phone number support
      phoneFormatted = options.truncate ? `${phoneFormatted.substring(0, 5)}...` : `${phoneFormatted.substring(0, 4)} ${phoneFormatted.substring(4, 8)}`;
      break;
    default:
      phoneFormatted = options.truncate ? `${phoneFormatted.substring(0, 7)}...` : phoneFormatted;
  }

  return { phoneFormatted, phoneUrl: phoneNumber.getURI() };
};

/**
 * @param  {String} word
 * @returns {String}
 * If the first letter is a vowel returns 'an' + work
 * otherwise it returns 'a' + word
 */
export const indefiniteArticleCheck = (word: string) => {
  if (!word || typeof word !== 'string') return word;
  const vowelRegex = '^[aieouAIEOU].*';
  return word.match(vowelRegex) ? `an ${word}` : `a ${word}`;
};

/**
 * @param  {String} phrase
 * @returns {String} First letter of the string is returned in uppercase
 */
export const capitalizeFirstLetter = (phrase: string) => {
  return phrase.charAt(0).toUpperCase() + phrase.slice(1);
};

export const escapeBackslash = (string: string) => string.replace(/\\/g, '\\\\');
export const escapeQuotes = (string: string) => (typeof string === 'string' ? string.replace(/"/g, '&quot;') : '');
export const squish = (string: string) => (typeof string === 'string' ? string.replace(/\r?\n|\r/g, ' ') : '');

/**
 * @param  {Array} wordList
 * @param  {String} conjunction
 * @returns {String} Returns a comma separated string with a conjunction insert before the last word
 */
export const naturalLanguageJoin = (wordList: Array<string>, conjunction = 'and') => {
  const compactWordList = compact(wordList);
  return compactWordList?.join(', ').replace(/, ([^,]*)$/, ` ${conjunction} $1`);
};

export const generateSuburbLinks = ({
  suburbs,
  categorySlug,
  stateSlug
}: GenerateSuburbLinksProps): Array<GenerateSuburbLinksReturnType> => suburbs
  .map(({ suburb, state }) => ({
    title: suburb,
    url: `/${categorySlug}/${slugHelper(state) || stateSlug}/${slugHelper(suburb)}`
  }));

export const generateRefNameFor = (title: string) => {
  // eslint-disable-next-line optimize-regex/optimize-regex
  return title?.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() || null;
};

export const findAnchorIn = (url: string) => {
  return generateRefNameFor(url.split('#')[url.split('#').length - 1]);
};

export const formatAbnNumber = (abn: string) => {
  if (!abn || abn.length === 0) return abn;

  const abnNew = String(abn);
  return abnNew.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
};

export const getBusinessDetailsLength = (value: string) => {
  if (value && value.match(/\S+/g)) {
    return value.match(/\S+/g).length;
  }
  return 0;
};

export const getTextWidth = (
  text: string,
  fontStyle: string,
  canvas: HTMLCanvasElement = document.createElement('canvas')
): number => {
  const context = canvas.getContext('2d');
  context.font = fontStyle || '16px lato,Arial,sans-serif';
  const dimension = context.measureText(text); // canvas measureText() API
  return Math.ceil(dimension.width);
};

export const toFixedIfNecessary = (value, dp) => {
  return +parseFloat(value).toFixed(dp);
};

const tConvert = (time) => {
  if (time === '24:00') return '12:00AM';
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) { // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
};

/**
* @param  {Number} number
* @returns {String}
* Converts a number to a string representing time
* e.g. 12:00
*/
export const convertNumberTo24HrTime = (number: number) => {
  // eslint-disable-next-line eqeqeq
  if (number == 0) return '0:00';
  const hour = `${number < 10 ? '0' : ''}${Math.floor(number)}`;
  const minute = number % 1 !== 0 ? String((Math.round((number % 1) * 60))) : '00';

  if (!Number(number)) return null;

  return `${hour}:${minute}`;
};

export const convertNumberToTime = (num: number) => {
  // eslint-disable-next-line eqeqeq
  const fixed = toFixedIfNecessary(num, 2);
  const number = fixed > 24 ? fixed - 24 : fixed;
  const hour = `${number < 10 ? '0' : ''}${Math.floor(number)}`;
  const minute = number % 1 !== 0 ? String((Math.round((number % 1) * 60))) : '00';

  return tConvert(`${hour}:${minute}`);
};

/**
 * @param  {String} time
 * @returns {Number}
 * Converts a time string representing time to a number
 * e.g.
 * "12:00" => 12
 * "08:30" => 8.5
 * "23:30" => 23.5
 */
export const convert24HrTimeToNumber = (time = '00:00'): number => {
  if (Number(time) || !time) return 0;

  const [hour, minute] = time.split(':');

  return (Number(hour) + (Number(minute) / 60));
};

/**
 * @param  {String} time
 * @returns {String}
 * Converts a 12-hour time string to a 24-hour time string
 * e.g.
 * "7:00 AM" => "07:00"
 * "03:29 pm" => "15:29"
 * "12:00pm" => "12:00"
 */
export const convert12HrTimeTo24HrTime = (time: string): string => {
  const hour = time.match(/\d+(?=:)/)[0];
  const colonAndMin = time.match(/:\d+/)[0];
  const formattedHour = time.toLowerCase().includes('a')
    ? hour === '12' ? '00' : hour.padStart(2, '0')
    : hour === '12' ? hour : String(Number(hour) + 12);
  return formattedHour + colonAndMin;
};

/**
 * @param  {String} time
 * @returns {String}
 * Converts an abbreviated day of week string to its full form
 * e.g.
 * "Mon" => "Monday"
 */
export const convertShortToFullDayOfWeek = (dayOfWeek: string): string => {
  const dayOfWeekMap = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday'
  };
  return dayOfWeekMap[dayOfWeek];
};

export const getHasPermission = (
  currentUser: Partial<ICurrentUser>,
  permission: string
) => (
  Boolean(currentUser?.user?.teamMemberPermissions?.[permission])
);

export const concatDisplayName = (firstName: string, lastName: string, displayName: string) => (
  `${firstName}${lastName ? ` ${lastName}` : ''}${displayName ? ` (${displayName})` : ''}`
);

export const getBlurFromUploadProgress = (percentage: number) => {
  switch (true) {
    case percentage <= 33:
      return '3px';
    case (percentage > 33 && percentage <= 67):
      return '2px';
    case (percentage > 67 && percentage < 100):
      return '1px';
    default:
      return 0;
  }
};

export const formatCurrentBusinessAddress = ({
  address,
  suburb,
  state,
  postcode
}: AddressProps) => {
  return [
    address || '',
    suburb || '',
    state || '',
    postcode || ''
  ].filter((val) => val).join(', ');
};

/**
 * Generates a version 4 UUID
 * @returns {String} uuid of string
 */
export const uuidv4 = (): string => {
  let uuid = '';
  let index;
  let random;

  for (index = 0; index < 32; index += 1) {
    random = Math.random() * 16 | 0;

    if (index === 8 || index === 12 || index === 16 || index === 20) {
      uuid += '-';
    }
    uuid += (index === 12 ? 4 : (index === 16 ? (random & 3 | 8) : random)).toString(16);
  }
  return uuid;
};

// #region get substring which match the term
export const getMatchChars = (string: string, term: string) => {
  let searchTerm;
  // prevent invalid expression
  try {
    searchTerm = new RegExp(term, 'gi');
  } catch (e) {
    searchTerm = term;
  }

  const matchIndex = string.search(searchTerm);
  if (matchIndex === -1) {
    return [{
      chars: string,
      isMatch: false
    }];
  }
  return [
    {
      chars: string.slice(0, matchIndex),
      isMatch: false
    },
    {
      chars: string.slice(matchIndex, matchIndex + term.length),
      isMatch: true
    }
  ].concat(getMatchChars(string.slice(matchIndex + term.length, string.length), term));
};
// #endregion

// #region check for bots
const botsWeCareAbout = ['google', 'bingbot'];

export const isBotWeCareAbout = (userAgent) => {
  const isBot = device(userAgent).is('bot');
  let isBotWeCareAbout = false;
  for (let i = 0; i < botsWeCareAbout.length; i += 1) {
    if (userAgent && userAgent.toLowerCase().indexOf(botsWeCareAbout[i]) > 0) {
      isBotWeCareAbout = true;
      break;
    }
  }
  return {
    isBot,
    staticRender: isBotWeCareAbout
  };
};

// #endregion

// #region check if a file is able to preview
export const fileAbleToRender = (fileName: string) => {
  const ableToRenderList = ['jpeg', 'png', 'jpg', 'gif'];
  const fileList = fileName?.split('.');
  const fileType = fileList?.[fileList.length - 1];
  return (fileType && ableToRenderList.includes(fileType));
};
// #endregion

/**
 * @param {String} categorySlug
 * @param {String} pathname
 * @returns {Boolean}
 * Checks if Job form v3 is enabled for a category on a path
 */
export const checkIsJobFormV3Enabled = ({ categorySlug, pathname }: {
  categorySlug: string;
  pathname: string;
}): boolean => {
  const isValidPath = JFV3_PATHS_AND_CATEGORIES.paths.includes(pathname);
  const isValidCategory = JFV3_PATHS_AND_CATEGORIES.categories.has(categorySlug);
  return (isValidCategory && isValidPath);
};

/*
* helper to add param to the end of the url
*/
export const appendParamOnURL = (
  paramKey: string,
  paramVaule: string | number,
  url: string
): string => {
  const newParam = `${paramKey}=${paramVaule}`;
  if (!url.includes('?')) {
    return `${url}?${newParam}`;
  }
  let appended = false;
  const paramsList = url.split('?')?.[1]?.split('&');
  return paramsList.reduce((pre: string, cur: string, index: number): string => {
    const connecter = index === 0 ? '?' : '&';
    let param = cur;
    if (cur.split('=')?.[0] === paramKey) {
      param = newParam;
      appended = true;
    }
    const newURL = `${pre}${connecter}${param}`;
    if (index === paramsList.length - 1) {
      return appended ? newURL : `${newURL}&${newParam}`;
    }
    return newURL;
  }, url.split('?')?.[0]);
};

/**
 * @param {string} str // e.g Popular Jobs
 * @returns {string} // popularjobs
 */
export const joinString = (str) => {
  return str.split(' ').join('').toLowerCase();
};

export const publicJobsCapitalCities = [
  'brisbane', 'melbourne', 'perth', 'sydney'
];

export const isValidURL = (urlString: string): boolean => {
  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 2
});

export const isNestedObject = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      return true;
    }
  }

  return false;
};

export const SITE_DEVISE_SIGN_OUT_ROUTE = '/users/sign_out';

export const logOutUsingSiteDeviseRoute = () => {
  window.location.href = SITE_DEVISE_SIGN_OUT_ROUTE;
};
