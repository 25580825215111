import { gql } from '@apollo/client';

import { ICurrentUser } from 'types/oneflare.com.au/user';

export const GET_USER = gql`
query CurrentUser {
  currentUser {
    trueUser {
      name
    }
    user {
      id
      name
      createdAt
      firstName
      lastName
      displayName
      email
      currentState
      currentSignInIp
      customerModeOverride
      unreadJobMessagesCount
      unseenQuoteMessagesCount
      lastSessionAt
      lastChangedPasswordAt
      lastSignInIp
      mobileVerificationRequired
      mobileVerified
      emailVerified
      phone
      roles
      initials
      teamMember {
        id
        role
        hasFullAccess: hasMaximumPermissions
      }
      teamMemberPermissions {
        leads
        teamMemberManagement
        leadSettings
        autoQuoteSettings
        missionControlSettings
        billingSettings
        quoteActivity
        planDetails
        businessProfile
      }
      avatar {
        url
      }
      business {
        id
        name
        businessVerificationStatus
        subscription {
          currentState
          id
        }
        category {
          profession {
            name
          }
        }
        instantAccessOnboardingCompleted
      }
      isBusiness
      creditBalance {
        current
        committed
      }
      jobMessagesCount
      quoteMessagesCount
      unseenSupportConversationCount: unseenSupportConversationsCount
      insurancePending
      insuranceRequired
    }
    notifications {
      id
      icon
      type
      message
      closable
      action
    }
    categoryId
    zoneId
    inboxUnreadCount
    jobFormHost
    showCreditsTooltip
    showCustomerInboxLink
  }
}
`;

export type GetCurrentUserResponse = {
  currentUser: ICurrentUser
};

export const USER_SWITCHER = gql`
  query {
    userSwitcher {
      standardUsers {
        id
        name
      }
      businessUsers {
        id
        name
        teamMembers {
          id
          name
        }
      }
    }
  }
`;
