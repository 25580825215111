export const ENTRY_PAGE_CATEGORIES = [
  'blocked-drains',
  'blocked-toilet',
  'carpet-repairs',
  'cat-grooming',
  'concrete-polishing',
  'dog-grooming',
  'financial-planner',
  'floor-sanding',
  'furniture-removals',
  'hot-water-services',
  'interstate-removals',
  'office-removals',
  'packing-unpacking-services',
  'pool-table-removals',
  'rainwater-tanks-installation',
  'removals-and-storage',
  'retaining-walls',
  'roof-leak-repair',
  'shower-bathtub-repair',
  'small-removals',
  'termite-inspection',
  'timber-flooring',
  'wedding-catering'
];

export const JFV3_PATHS_AND_CATEGORIES = {
  paths: [
    '/oneflare.com.au/[categorySlug]',
    '/oneflare.com.au/[categorySlug]/[state]/[suburb]'
  ],
  categories: new Set([
    // car categories
    'car-detailing',
    'car-window-tinting',
    'mechanic',
    'panel-beaters',
    'prepurchase-car-inspection',
    'towing',
    'windscreen-repair',
    // wedding categories
    'wedding-florist',
    // others
    'massage',
    'naturopath',
    'office-admin',
    'physiotherapy',
    'pool-cleaners',
    'real-estate-agent',
    'seo',
    'welding'
  ])
};

/**
 * index 0 categorySlug,
 * index 1 state,
 * index 2 suburb,
*/
export const CAT_LOC_URLS = [
  ['accountant', 'nsw', 'newcastle'],
  ['accountant', 'nsw', 'sydney'],
  ['accountant', 'qld', 'brisbane'],
  ['accountant', 'qld', 'sunshine-coast'],
  ['accountant', 'tas', 'hobart'],
  ['accountant', 'vic', 'geelong'],
  ['accountant', 'vic', 'melbourne'],
  ['accountant', 'wa', 'perth'],
  ['arborist', 'nsw', 'newcastle'],
  ['arborist', 'nsw', 'sydney'],
  ['arborist', 'qld', 'brisbane'],
  ['arborist', 'qld', 'cairns'],
  ['arborist', 'sa', 'adelaide'],
  ['arborist', 'tas', 'hobart'],
  ['arborist', 'vic', 'melbourne'],
  ['arborist', 'wa', 'perth'],
  ['architect', 'nsw', 'newcastle'],
  ['architect', 'nsw', 'sydney'],
  ['architect', 'qld', 'brisbane'],
  ['architect', 'sa', 'adelaide'],
  ['architect', 'tas', 'hobart'],
  ['architect', 'vic', 'melbourne'],
  ['architect', 'wa', 'perth'],
  ['builders', 'act', 'canberra'],
  ['builders', 'nsw', 'newcastle'],
  ['builders', 'nsw', 'sydney'],
  ['builders', 'qld', 'brisbane'],
  ['builders', 'qld', 'cairns'],
  ['builders', 'sa', 'adelaide'],
  ['builders', 'tas', 'hobart'],
  ['builders', 'vic', 'melbourne'],
  ['builders', 'wa', 'perth'],
  ['celebrant', 'act', 'canberra'],
  ['celebrant', 'nsw', 'newcastle'],
  ['celebrant', 'nsw', 'sydney'],
  ['celebrant', 'qld', 'brisbane'],
  ['celebrant', 'qld', 'cairns'],
  ['celebrant', 'sa', 'adelaide'],
  ['celebrant', 'vic', 'melbourne'],
  ['celebrant', 'wa', 'perth'],
  ['cleaner', 'act', 'canberra'],
  ['cleaner', 'nsw', 'newcastle'],
  ['cleaner', 'nsw', 'sydney'],
  ['cleaner', 'qld', 'brisbane'],
  ['cleaner', 'qld', 'gold-coast'],
  ['cleaner', 'sa', 'adelaide'],
  ['cleaner', 'vic', 'melbourne'],
  ['cleaner', 'wa', 'perth'],
  ['concreting', 'nsw', 'newcastle'],
  ['concreting', 'nsw', 'sydney'],
  ['concreting', 'qld', 'brisbane'],
  ['concreting', 'qld', 'cairns'],
  ['concreting', 'sa', 'adelaide'],
  ['concreting', 'tas', 'hobart'],
  ['concreting', 'vic', 'melbourne'],
  ['concreting', 'wa', 'perth'],
  ['demolition', 'act', 'canberra'],
  ['demolition', 'nsw', 'newcastle'],
  ['demolition', 'nsw', 'sydney'],
  ['demolition', 'qld', 'brisbane'],
  ['demolition', 'qld', 'cairns'],
  ['demolition', 'sa', 'adelaide'],
  ['demolition', 'tas', 'hobart'],
  ['demolition', 'vic', 'melbourne'],
  ['demolition', 'wa', 'perth'],
  ['djs', 'act', 'canberra'],
  ['djs', 'nsw', 'newcastle'],
  ['djs', 'nsw', 'newcastle'],
  ['djs', 'nsw', 'sydney'],
  ['djs', 'qld', 'brisbane'],
  ['djs', 'sa', 'adelaide'],
  ['djs', 'vic', 'melbourne'],
  ['djs', 'wa', 'perth'],
  ['flooring', 'act', 'canberra'],
  ['flooring', 'nsw', 'newcastle'],
  ['flooring', 'nsw', 'newcastle'],
  ['flooring', 'nsw', 'sydney'],
  ['flooring', 'qld', 'brisbane'],
  ['flooring', 'qld', 'cairns'],
  ['flooring', 'sa', 'adelaide'],
  ['flooring', 'tas', 'hobart'],
  ['flooring', 'vic', 'melbourne'],
  ['flooring', 'wa', 'perth'],
  ['graphic-design', 'act', 'canberra'],
  ['graphic-design', 'nsw', 'newcastle'],
  ['graphic-design', 'nsw', 'sydney'],
  ['graphic-design', 'qld', 'brisbane'],
  ['graphic-design', 'qld', 'cairns'],
  ['graphic-design', 'sa', 'adelaide'],
  ['graphic-design', 'sa', 'adelaide'],
  ['graphic-design', 'tas', 'hobart'],
  ['graphic-design', 'vic', 'melbourne'],
  ['graphic-design', 'wa', 'perth'],
  ['landscaping', 'act', 'canberra'],
  ['landscaping', 'nsw', 'newcastle'],
  ['landscaping', 'nsw', 'sydney'],
  ['landscaping', 'qld', 'brisbane'],
  ['landscaping', 'qld', 'cairns'],
  ['landscaping', 'sa', 'adelaide'],
  ['landscaping', 'tas', 'hobart'],
  ['landscaping', 'vic', 'melbourne'],
  ['landscaping', 'wa', 'perth'],
  ['lawyers', 'act', 'canberra'],
  ['lawyers', 'nsw', 'newcastle'],
  ['lawyers', 'nsw', 'sydney'],
  ['lawyers', 'qld', 'brisbane'],
  ['lawyers', 'qld', 'cairns'],
  ['lawyers', 'sa', 'adelaide'],
  ['lawyers', 'vic', 'melbourne'],
  ['lawyers', 'wa', 'perth'],
  ['painters', 'act', 'canberra'],
  ['painters', 'nsw', 'sydney'],
  ['painters', 'qld', 'brisbane'],
  ['painters', 'qld', 'gold-coast'],
  ['painters', 'sa', 'adelaide'],
  ['painters', 'tas', 'hobart'],
  ['painters', 'vic', 'melbourne'],
  ['painters', 'wa', 'perth'],
  ['pest-control', 'act', 'canberra'],
  ['pest-control', 'nsw', 'sydney'],
  ['pest-control', 'qld', 'brisbane'],
  ['pest-control', 'qld', 'cairns'],
  ['pest-control', 'sa', 'adelaide'],
  ['pest-control', 'tas', 'hobart'],
  ['pest-control', 'vic', 'melbourne'],
  ['pest-control', 'wa', 'perth'],
  ['photographer', 'act', 'canberra'],
  ['photographer', 'nsw', 'newcastle'],
  ['photographer', 'nsw', 'sydney'],
  ['photographer', 'qld', 'brisbane'],
  ['photographer', 'qld', 'cairns'],
  ['photographer', 'sa', 'adelaide'],
  ['photographer', 'tas', 'hobart'],
  ['photographer', 'vic', 'melbourne'],
  ['photographer', 'wa', 'perth'],
  ['plastering', 'act', 'canberra'],
  ['plastering', 'nsw', 'newcastle'],
  ['plastering', 'nsw', 'sydney'],
  ['plastering', 'qld', 'brisbane'],
  ['plastering', 'qld', 'cairns'],
  ['plastering', 'sa', 'adelaide'],
  ['plastering', 'tas', 'hobart'],
  ['plastering', 'vic', 'melbourne'],
  ['plastering', 'wa', 'perth'],
  ['removalists', 'act', 'canberra'],
  ['removalists', 'nsw', 'sydney'],
  ['removalists', 'qld', 'brisbane'],
  ['removalists', 'qld', 'gold-coast'],
  ['removalists', 'sa', 'adelaide'],
  ['removalists', 'tas', 'hobart'],
  ['removalists', 'vic', 'melbourne'],
  ['removalists', 'wa', 'perth'],
  ['rendering-and-resurfacing', 'act', 'canberra'],
  ['rendering-and-resurfacing', 'nsw', 'newcastle'],
  ['rendering-and-resurfacing', 'nsw', 'sydney'],
  ['rendering-and-resurfacing', 'qld', 'brisbane'],
  ['rendering-and-resurfacing', 'qld', 'cairns'],
  ['rendering-and-resurfacing', 'sa', 'adelaide'],
  ['rendering-and-resurfacing', 'sa', 'adelaide'],
  ['rendering-and-resurfacing', 'tas', 'hobart'],
  ['rendering-and-resurfacing', 'vic', 'melbourne'],
  ['rendering-and-resurfacing', 'wa', 'perth'],
  ['roof-painting', 'act', 'canberra'],
  ['roof-painting', 'nsw', 'newcastle'],
  ['roof-painting', 'nsw', 'sydney'],
  ['roof-painting', 'qld', 'brisbane'],
  ['roof-painting', 'qld', 'cairns'],
  ['roof-painting', 'qld', 'cairns'],
  ['roof-painting', 'sa', 'adelaide'],
  ['roof-painting', 'tas', 'hobart'],
  ['roof-painting', 'vic', 'melbourne'],
  ['roof-painting', 'wa', 'perth'],
  ['roof-painting', 'wa', 'perth'],
  ['tiling', 'nsw', 'newcastle'],
  ['tiling', 'nsw', 'sydney'],
  ['tiling', 'qld', 'brisbane'],
  ['tiling', 'qld', 'cairns'],
  ['tiling', 'qld', 'cairns'],
  ['tiling', 'sa', 'adelaide'],
  ['tiling', 'tas', 'hobart'],
  ['tiling', 'vic', 'melbourne'],
  ['tiling', 'wa', 'perth'],
  ['web-design', 'act', 'canberra'],
  ['web-design', 'nsw', 'newcastle'],
  ['web-design', 'nsw', 'sydney'],
  ['web-design', 'qld', 'brisbane'],
  ['web-design', 'qld', 'cairns'],
  ['web-design', 'sa', 'adelaide'],
  ['web-design', 'tas', 'hobart'],
  ['web-design', 'vic', 'melbourne'],
  ['web-design', 'vic', 'melbourne'],
  ['web-design', 'wa', 'perth'],
  ['window-cleaning', 'nsw', 'newcastle'],
  ['window-cleaning', 'nsw', 'newcastle'],
  ['window-cleaning', 'nsw', 'sydney'],
  ['window-cleaning', 'qld', 'brisbane'],
  ['window-cleaning', 'qld', 'cairns'],
  ['window-cleaning', 'qld', 'cairns'],
  ['window-cleaning', 'sa', 'adelaide'],
  ['window-cleaning', 'vic', 'melbourne'],
  ['window-cleaning', 'vic', 'melbourne']
];

export const catLocNavList = [
  { title: 'Top Category', value: 'topCategory' },
  { title: 'Popular Jobs', value: 'popularJobs' },
  { title: 'Category FAQs', value: 'categoryFaqs' },
  { title: 'Category Cost Guides', value: 'categoryCostGuides' }
];

export const CONTENT_HUB_WP_PATH = 'https://content-hub.oneflare.com.au';
export const INSPIRATION_WP_CATEGORY_ID = 2;
// 203 - Win work guide
// 389, 381, 380, 406 - Business centre
export const EXCLUDE_WP_CATEGORIES = '203,380,389,406,381';

export const howOneflareWorksItemsData = [
  {
    mobileImage: 'https://cdn.oneflare.com/static/client/business-centre/register-business.svg',
    desktopImage: 'https://cdn.oneflare.com/static/client/business-centre/register-business.svg',
    title: 'Register your business',
    description: 'Create an account then chat with us to find the right solution to achieve your goals. '
  },
  {
    desktopImage: 'https://cdn.oneflare.com/static/client/oneflare/how-it-works-customer-step-1.svg',
    title: 'Build your profile',
    description: 'Update your profile with an image gallery, reviews, credentials and more to engage your customers.'
  },
  {
    desktopImage: 'https://cdn.oneflare.com/static/client/business-centre/business-centre-tools.svg',
    title: 'Decide on the jobs you want',
    description: 'Choose your own lead preferences and we’ll connect you with the opportunities that suit.'
  }
];

export const howOneflareWorksData = {
  title: 'How Oneflare works',
  description: 'Oneflare is empowering businesses across Australia to grow their customer base by using the best tools to access, engage and win work.'
};

export const BUSINESS_CENTRE_WP_CATEGORY_ID = 380;
export const TOP_TOOLS_WP_CATEGORY_ID = 381;
export const INCLUDE_WP_CATEGORIES = '380,389,406,381';

export const firstExperimentalCategories = new Set(['pet-sitting', 'tv-repairs', 'personal-trainer']);
export const secondExperimentalCategories = new Set(['acupuncture', 'gardener', 'courier']);

export const experimentJira = 'of-27367';
export const experimentVariant = ['control', 'variant2'];
export const experimentWeights = [50, 50];

export const CONTACT_NUMBER = '1300 441 593';

export const FEATURE_FLAGS = {
  stripeSubscription: 'stripe_subscriptions_enabled'
};
