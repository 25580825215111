import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { setupOneflareFeatureManagerClientAuthenticated, setupOneflareFeatureManagerClientUnauthenticated } from 'lib/features/clientSetup';
import { useCurrentUser } from 'shared/utils/getCurrentUser';

import { OneflareAnalytics } from './oneflareAnalytics';

const OneflareAnalyticsPageTrackerWrapper = () => {
  useCurrentUser({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      if (data.currentUser) {
        const currentUser = data.currentUser;
        const tracker = new OneflareAnalytics(String(currentUser.user.id));
        setupOneflareFeatureManagerClientAuthenticated(
          tracker,
          String(currentUser.user.id)
        );
        tracker.segmentPageEvent();
      } else {
        const tracker = new OneflareAnalytics();
        setupOneflareFeatureManagerClientUnauthenticated(tracker);
        tracker.segmentPageEvent();
      }
    },
    onError: (error) => {
      DataDogRumAgent.addRumError(error, 'Oneflare | OneflareAnalyticsPageTrackerWrapper');
      const tracker = new OneflareAnalytics();
      setupOneflareFeatureManagerClientUnauthenticated(tracker);
      tracker.segmentPageEvent();
    }
  });
  return null;
};

export default OneflareAnalyticsPageTrackerWrapper;
