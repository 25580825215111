import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Subscription,
  switchMap,
  take
} from 'rxjs';

import { FeatureManager } from 'lib/features/featureManager';
import {
  BehaviorSubjectUserAnswers,
  Id,
  JobFormQuestions
} from 'types/oneflare.com.au/jobForm';

import { DESCRIPTION_QUESTION } from './constants';

enum ExperimentParameterType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMBER = 'number'
}

enum ExperimentCategory {
  BY_CATEGORY = 'by_category',
  BY_ANSWER = 'by_answer'
}

type ActiveJobFormQuestionsExperiment = {
  key: string;
  parameter: string;
  type: ExperimentParameterType;
  questionBasis: string;
  questionAnswerBasis: string;
  experimentCategory: ExperimentCategory;
  questionToUpdate: {
    id: Id;
    propertyToModify: {
      name: string;
      value: string | boolean | number;
    };
  };
};

const DECKING_BUILD_NEW_DECK_EXPERIMENT_KEY = 'oneflare-3109-web-decking-build-new-deck-description-placeholder-experiment';

const ActiveJobFormQuestionsExperiments: Record<
  string,
  Array<ActiveJobFormQuestionsExperiment>
> = {
  Decking: [
    {
      key: DECKING_BUILD_NEW_DECK_EXPERIMENT_KEY,
      parameter: 'placeholder_text',
      type: ExperimentParameterType.STRING,
      experimentCategory: ExperimentCategory.BY_ANSWER,
      questionBasis: 'Which decking service do you need?',
      questionAnswerBasis: 'Build new deck',
      questionToUpdate: {
        id: DESCRIPTION_QUESTION.id,
        propertyToModify: {
          name: 'placeholder',
          value: DESCRIPTION_QUESTION.placeholder
        }
      }
    }
  ]
};

class JobFormQuestionsExperimentService {
  constructor(private featureManager: FeatureManager) {
    this.featureManager = featureManager;
  }

  isPartOfActiveExperiment(
    category: string
  ): undefined | Array<ActiveJobFormQuestionsExperiment> {
    if (ActiveJobFormQuestionsExperiments[category] !== undefined) {
      return ActiveJobFormQuestionsExperiments[category];
    }
    return undefined;
  }

  getExperimentVariable(
    experiment: ActiveJobFormQuestionsExperiment
  ): string | boolean | number {
    switch (experiment.type) {
      case ExperimentParameterType.STRING:
        return this.featureManager.getFeatureVariableString(
          experiment.key,
          experiment.parameter,
          experiment.questionToUpdate.propertyToModify.value as string
        );
      case ExperimentParameterType.BOOLEAN:
        return this.featureManager.getFeatureVariableBoolean(
          experiment.key,
          experiment.parameter,
          experiment.questionToUpdate.propertyToModify.value as boolean
        );
      case ExperimentParameterType.NUMBER:
        return this.featureManager.getFeatureVariableInteger(
          experiment.key,
          experiment.parameter,
          experiment.questionToUpdate.propertyToModify.value as number
        );
    }
  }

  /**
   * Applies all applicable experiments to a set of questions based on category
   * Returns a new set of questions if modifications were made
   */
  applyExperimentsToQuestions(
    questionsObservable: BehaviorSubject<JobFormQuestions>,
    categoryName: string,
    userAnswersObservable: BehaviorSubjectUserAnswers
  ): Subscription | Subscription[] {
    const activeExperiment = this.isPartOfActiveExperiment(categoryName);
    if (!activeExperiment) {
      return Subscription.EMPTY;
    }

    const subscriptions = activeExperiment.map((experiment) => {
      if (experiment.experimentCategory === ExperimentCategory.BY_CATEGORY) {
        return this.applyExperimentsToQuestionsByCategory(
          questionsObservable,
          experiment
        );
      }

      if (experiment.experimentCategory === ExperimentCategory.BY_ANSWER) {
        return this.applyExperimentsToQuestionsByAnswer(
          questionsObservable,
          experiment,
          userAnswersObservable
        );
      }
      return Subscription.EMPTY;
    });

    return subscriptions;
  }

  /**
   * Applies experiments to a set of job form questions by category.
   * This method observes changes to the provided `questionsObservable` and updates
   * the questions based on the active experiment configuration. It ensures that
   * the experiment's modifications are applied only when necessary and emits the
   * updated questions back to the observable.
   *
   * @param questionsObservable - A `BehaviorSubject` that holds the current state of job form questions.
   * @param activeExperiment - The active experiment configuration that specifies
   * the question to update, the property to modify, and the experiment value.
   *
   */
  applyExperimentsToQuestionsByCategory(
    questionsObservable: BehaviorSubject<JobFormQuestions>,
    activeExperiment: ActiveJobFormQuestionsExperiment
  ) {
    const updates$ = questionsObservable.pipe(
      // Only proceed when questions array actually changes
      distinctUntilChanged(isEqual),
      map((questions) => {
        // Find the question we need to modify based on its ID
        const questionIndex = questions.findIndex(
          ({ id }) => id === activeExperiment.questionToUpdate.id
        );

        // Only proceed if we found the target question
        if (questionIndex !== -1) {
          // Get the experiment value from the feature manager
          const experimentValue = this.getExperimentVariable(activeExperiment);

          // Check if the value actually needs to be updated
          // This prevents unnecessary updates if the value is already correct
          if (
            questions[questionIndex][activeExperiment.questionToUpdate.propertyToModify.name] !==
            experimentValue
          ) {
            // Create a new array to maintain immutability
            const updatedQuestions = [...questions];

            // Create a new question object with the updated property
            updatedQuestions[questionIndex] = {
              ...updatedQuestions[questionIndex],
              [activeExperiment.questionToUpdate.propertyToModify.name]: experimentValue
            };
            return updatedQuestions;
          }
        }
        // If no change is needed, return the original questions array
        return questions;
      }),
      // Prevent emitting duplicate question arrays
      distinctUntilChanged(isEqual)
    );

    return updates$.subscribe((updatedQuestions) => {
      // Only call next if value is actually different
      const current = questionsObservable.getValue();
      if (!isEqual(current, updatedQuestions)) {
        questionsObservable.next(updatedQuestions);
      }
    });
  }

  /**
   * Applies experiments to job form questions based on user answers.
   *
   * This method creates a reactive pipeline that:
   * 1. Monitors changes to user answers
   * 2. Determines if a specific answer matches the experiment condition
   * 3. Updates the relevant question with either the experiment value or default value
   *
   * The method ensures that changes are only applied when necessary by:
   * - Only processing when user answers actually change (using distinctUntilChanged)
   * - Only updating question properties that differ from their current values
   * - Only emitting new question values when they differ from the current state
   *
   * @param questionsObservable - A `BehaviorSubject` that holds the current state of job form questions
   * @param activeExperiment - The experiment configuration that specifies which question to update
   *                          based on a specific user answer to another question
   * @param userAnswersObservable - A `BehaviorSubject` that holds the current state of user answers
   *
   * @returns A Subscription that can be used to unsubscribe from the observable chain
   */
  applyExperimentsToQuestionsByAnswer(
    questionsObservable: BehaviorSubject<JobFormQuestions>,
    activeExperiment: ActiveJobFormQuestionsExperiment,
    userAnswersObservable: BehaviorSubjectUserAnswers
  ): Subscription {
    return userAnswersObservable
      .pipe(
        // Only proceed when user answers actually change
        distinctUntilChanged(isEqual),

        // Filter out cases where the relevant user answer doesn't exist
        filter((userAnswers) => {
          const question = questionsObservable.getValue().find(
            ({ question }) => question === activeExperiment.questionBasis
          );
          const userAnswer = userAnswers[question?.id]?.userAnswer;
          return userAnswer !== undefined;
        }),

        // Determine if the user's answer matches the experiment's condition
        map((userAnswers) => {
          const question = questionsObservable.getValue().find(
            ({ question }) => question === activeExperiment.questionBasis
          );
          const userAnswer = userAnswers[question?.id]?.userAnswer;
          // Handle string-type user answers (direct comparison)
          if (userAnswer && typeof userAnswer === 'string') {
            return userAnswer === activeExperiment.questionAnswerBasis;
          }
          // Handle object-type user answers (compare the 'value' property)
          else if (userAnswer && typeof userAnswer === 'object' && 'value' in userAnswer) {
            return userAnswer.value === activeExperiment.questionAnswerBasis;
          }

          // Default case: no match
          return false;
        }),
        // Only process when the matching status changes (prevent duplicate work)
        distinctUntilChanged(isEqual),
        // use switchMap to switch to the questions observable
        switchMap((shouldApplyExperiment) => questionsObservable.pipe(
          // take only the first emitted value
          take(1),
          map(questions => {
            // Get either the experiment value or default value based on whether the answer matches
            const experimentValue = shouldApplyExperiment
              ? this.getExperimentVariable(activeExperiment)
              : activeExperiment.questionToUpdate.propertyToModify.value;

            // Find the target question that needs to be updated
            const questionIndex = questions.findIndex(
              ({ id }) => id === activeExperiment.questionToUpdate.id
            );

            // Only update if the question exists and its property needs changing
            if (
              questionIndex !== -1 &&
              questions[questionIndex][activeExperiment.questionToUpdate.propertyToModify.name] !==
                experimentValue
            ) {
              // Create a new questions array (for immutability)
              const updatedQuestions = [...questions];

              // Update the specific property on the target question
              updatedQuestions[questionIndex] = {
                ...updatedQuestions[questionIndex],
                [activeExperiment.questionToUpdate.propertyToModify.name]: experimentValue
              };

              return updatedQuestions;
            }

            // Return original questions if no update is needed
            return questions;
          })
        ))
      ).subscribe((questions) => {
        // Emit the updated questions value back to the observable
        questionsObservable.next(questions);
      });
  }
}

export {
  JobFormQuestionsExperimentService,
  ActiveJobFormQuestionsExperiments,
  DECKING_BUILD_NEW_DECK_EXPERIMENT_KEY,
  ExperimentCategory,
  ExperimentParameterType,
  type ActiveJobFormQuestionsExperiment
};
