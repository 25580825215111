import {
  AnonymousUser,
  AuthenticatedUser,
  Environment,
  User
} from '@airtasker/kmp-feature-management';

import { getFeatureManagementEnvironmentConfig } from 'lib/utils/Environment';

import { UserContext } from './featureManager';

function convertUserContextToFeatureManagementUser(
  userContext: UserContext
): User {
  if (userContext.isAuthenticated) {
    return new AuthenticatedUser(userContext.userId, userContext.regionCode);
  }
  return new AnonymousUser(userContext.userId, userContext.regionCode);
}

const FAKE_TESTING_ENVIRONMENT = 'TESTING';

function getFeatureManagementEnvironment(): Environment {
  const { environment } = getFeatureManagementEnvironmentConfig();

  if (environment === FAKE_TESTING_ENVIRONMENT) {
    return Environment.DEVELOPMENT;
  }

  return Environment.valueOf(environment);
}

function isFeatureManagementInTestingMode(): boolean {
  const { environment } = getFeatureManagementEnvironmentConfig();
  return environment === FAKE_TESTING_ENVIRONMENT;
}

export {
  convertUserContextToFeatureManagementUser,
  getFeatureManagementEnvironment,
  isFeatureManagementInTestingMode
};
