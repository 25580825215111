import {
  trackJobFormInitiated,
  trackJobFormExited,
  trackJobFormCompleted,
  trackJobFormCategorySet,
  trackJobFormLocationSet,
  trackJobFormQuestionCompleted,
  trackJobFormQuestionInitiated,
  type TrackJobFormInitiatedOptions,
  type TrackJobFormExitedOptions,
  type TrackJobFormCompletedOptions,
  type TrackJobFormQuestionInitiatedOptions,
  type TrackJobFormQuestionCompletedOptions,
  type TrackJobFormCategorySetOptions,
  type TrackJobFormLocationSetOptions
} from '@oneflare/web-analytics';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import dynamic from 'next/dynamic';
import type { ComponentType } from 'react';
import { Component } from 'react';
import { distinctUntilChanged, filter, Subscription, tap } from 'rxjs';

import { OneflareAnalytics } from 'lib/analytics/oneflareAnalytics';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { getClientFeatureManager } from 'lib/features/featureManager';
import {
  CATEGORY_QUESTION_ID,
  CONTACT_DETAILS_ID,
  EMAIL_QUESTION_ID,
  GENERAL_QUESTION_IDS,
  JOB_FORM_CATEGORY_ID,
  JOB_FORM_OPEN,
  LOCATION_QUESTION_ID,
  MOBILE_VERIFICATION_ID,
  MOBILE_VERIFICATION_QUESTION
} from 'lib/oneflare-job-form/utils/constants';
import { JobFormControllerContext, intialJobFormControllerState } from 'lib/oneflare-job-form/utils/context';
import jobFormHelper, { GetQuestionFlag } from 'lib/oneflare-job-form/utils/jobFormHelper';
import JobFormService from 'lib/oneflare-job-form/utils/JobFormService';
import TrackingHelper from 'lib/oneflare-job-form/utils/trackingHelper';
import { getDomain, getJobFormUrl } from 'lib/utils/Environment';
import type { CreateJobReponse } from 'mutations/shared/jobForm';
import { isValidURL } from 'shared/utils/helpers';
import type * as jobFormTypes from 'types/oneflare.com.au/jobForm';

import { JobFormErrorHandler } from '../utils/jobFormErrorHandler';
import { QuestionHandlerService } from '../utils/jobFormQuestionHandlerService';
import { JobFormQuestionsExperimentService } from '../utils/jobFormQuestionsExperimentService';

const JobForm = dynamic(() => import('lib/oneflare-job-form/JobForm'), { ssr: false });
const DOMAIN = getDomain();
const JOB_FORM_URL = getJobFormUrl();

const withJobFormController = (WrappedComponent: ComponentType<Record<string, unknown>>) => {
  return class JobFormController extends Component<jobFormTypes.IJobFormControllerProps, jobFormTypes.JobFormState> implements jobFormTypes.IJobFormController {
    constructor(props) {
      super(props);
      this.DOMAIN = DOMAIN;
      this.state = intialJobFormControllerState(DOMAIN);

      this.jobFormErrorHandler = new JobFormErrorHandler(this.state.jobFormFailureListener$);
      this.questionHandlerService = new QuestionHandlerService();
    }

    async componentDidMount() {
      const { originalReferer: ogReferrer, pageProps } = this.props;
      const categoryId = pageProps?.categoryId;
      const ogRefererPageProps = pageProps?.originalReferer;
      const originalReferer = ogReferrer ?? ogRefererPageProps;
      const validUrl = isValidURL(originalReferer) ? new URL(originalReferer).origin : 'https://www.oneflare.com.au/';
      // with ssr and client side generated pages tracker assignment happens
      // when component gets mounted as original referrer is already available on server side
      this.trackingHelper = new TrackingHelper({
        answerValue: '',
        categoryId,
        formStep: '',
        jobId: null,
        label: '',
        locationId: null,
        originalReferer: validUrl,
        questionId: null,
        serviceTypeId: []
      });

      // initialize service on client side to have access to apollo cache
      this.jobFormService = new JobFormService();

      // track job form landing
      this.trackingHelper.trackJobFormLanding({ categoryId });
      // get current user and initialize questions and answers
      const currentUser = await this.jobFormService.getCurrentUser();
      if (currentUser) {
        this.oneflareAnalytics = new OneflareAnalytics(String(currentUser.user.id));
        this.setState({ currentUser });
        this.state.isLoggedIn.next(true);
      } else {
        this.oneflareAnalytics = new OneflareAnalytics();
      }
      await this.initializeQuestionsAndAnswers();
    }

    componentWillUnmount() {
      // ensure we clean up any rxjs subscriptions and feature manager subscriptions
      this.close();
    }

    handleSetLocationAnswer = ({ locationId, locationName }: jobFormTypes.IHandleSetLocationAnswer) => {
      const { questions$, userAnswers } = this.state;
      const userAnswersCopy = { ...userAnswers.value };
      if (userAnswersCopy[LOCATION_QUESTION_ID]) {
        userAnswersCopy[LOCATION_QUESTION_ID].userAnswer = {
          value: locationId,
          name: locationName
        } as jobFormTypes.UserAnswer;
      }
      userAnswers.next(userAnswersCopy);
      if (locationId) {
        const nextQuestions = questions$.value.filter(({ id }) => id !== LOCATION_QUESTION_ID);
        questions$.next(nextQuestions);
      }
    };

    handleSendMobileCode = async (email: string, isResendRequest: boolean) => {
      try {
        const sendMobileCodeData = await this.jobFormService.sendMobileCode(email);
        this.setState({ sendMobileCodeData });
        if (isResendRequest && sendMobileCodeData?.status === 'success') {
          this.trackingHelper.trackInteraction('resendCodeSuccess');
        }
      } catch (exception) {
        this.setState({ sendMobileCodeData: null });
        this.jobFormErrorHandler.handleError(exception, 'Oneflare | HOC withJobFormController | SEND_MOBILE_CODE mutation');
      }
    };

    handleVerifyMobileCode = async (token: string): Promise<boolean> => {
      const { jobFormFailureListener$, isLoading$ } = this.state;
      this.setState({ isVerifyCodeLoading: true });
      isLoading$.next(true);
      jobFormFailureListener$.next({ failed: false });
      const { createPendingJobAttrs: { email }} = this.state;
      try {
        const verifyMobileCodeData = await this.jobFormService.verifyMobileCode( email, token );
        this.setState({ isVerifyCodeLoading: false });
        if (!verifyMobileCodeData) return false;
        this.setState({ verifyMobileCodeData });
        const { status } = verifyMobileCodeData;
        const successfullyVerified = status === 'success';
        if (successfullyVerified) {
          await this.handleCreateJob({ questionId: MOBILE_VERIFICATION_ID });
        }
        isLoading$.next(false);
        return successfullyVerified;
      } catch (exception) {
        isLoading$.next(false);
        this.jobFormErrorHandler.handleError(exception, 'Oneflare | HOC withJobFormController | VERIFY_MOBILE_CODE mutation');
        return false;
      }
    };

    handleSendEmailLink = async () => {
      this.setState({ isEmailLinkLoading: true });
      const { pendingJobUuid$, isEmailLinkSent$ } = this.state;
      await this.jobFormErrorHandler.wrapWithErrorHandling({
        operation: async () => {
          const returnedPendingJobId = await this.jobFormService.sendEmailLink(pendingJobUuid$.value);
          if (returnedPendingJobId) {
            isEmailLinkSent$.next(true);
          }
        },
        context:'Oneflare | HOC withJobFormController | SEND_EMAIL_LINK mutation'
      });
      this.setState({ isEmailLinkLoading: false });
      this.trackingHelper.trackInteraction('sendMagicLink');
    };

    handleResetPassword = async () => {
      const { createPendingJobAttrs: { email }, resetPasswordEmailRequestSent$, resetPasswordEmailRequestLoading$ } = this.state;
      resetPasswordEmailRequestLoading$.next(true);
      await this.jobFormErrorHandler.wrapWithErrorHandling({
        operation: async () => {
          const result = await this.jobFormService.resetPassword(email);
          resetPasswordEmailRequestSent$.next(result);
        },
        context: 'Oneflare | HOC withJobFormController | handleResetPassword method'
      });
      resetPasswordEmailRequestLoading$.next(false);
    };

    open = async ({
      categoryId,
      postcode,
      locationId = 0,
      locationName,
      page,
      section,
      ctaText,
      categoryName
    }: jobFormTypes.JobFormOpenArgs = {}) => {
      const { openJobForm$ } = this.state;
      this.setState({
        jobFormCity: locationName,
        jobFormCategoryName: categoryName,
        jobFormCategoryId: Number(categoryId),
        jobFormPostcode: postcode,
        jobFormIniatedSection: section,
        jobFormInitiatedPage: page,
        jobFormInitiatedCtaText: ctaText
      });

      const trackInitiatedOptions = {
        initiatedPage: page,
        initiatedSection: section,
        initiatedCtaText: ctaText,
        isLoggedIn: this.state.isLoggedIn.value,
        jobFormCategoryName: categoryName,
        jobFormCategoryId: categoryId,
        jobFormPostcode: postcode,
        jobFormCity: locationName
      } as TrackJobFormInitiatedOptions;
      trackJobFormInitiated(this.oneflareAnalytics, 'withJobFormController:open', trackInitiatedOptions);
      DataDogRumAgent.addCustomKey(JOB_FORM_OPEN, true);
      this.trackingHelper.trackJobFormLanding({ categoryId, locationId });

      if (categoryId) {
        DataDogRumAgent.addCustomKey(JOB_FORM_CATEGORY_ID, categoryId);
        trackJobFormCategorySet(this.oneflareAnalytics, 'withJobFormController:open', {
          jobFormCategoryId: categoryId,
          jobFormCategoryName: categoryName,
          isLoggedIn: this.state.isLoggedIn.value
        } as TrackJobFormCategorySetOptions);
        await this.initializeQuestionsAndAnswers(categoryId, postcode);
      }

      if (locationId && locationId !== 0) {
        if (locationName && postcode) {
          trackJobFormLocationSet(this.oneflareAnalytics, 'withJobFormController:open', {
            jobFormPostcode: postcode,
            jobFormCity: locationName,
            isLoggedIn: this.state.isLoggedIn.value
          } as TrackJobFormLocationSetOptions);
        }
        this.handleSetLocationAnswer({
          locationId: String(locationId),
          locationName
        });
      }
      openJobForm$.next(true);
      this.registerJobFormQuestionsExperiment(categoryName);
    };

    close = () => {
      let initialState = intialJobFormControllerState(this.DOMAIN);
      const { currentUser } = this.state;
      trackJobFormExited(this.oneflareAnalytics, 'withJobFormController:close', {
        questionId: this.state.currentQuestionIdForTracking,
        questionText: this.state.currrentQuestionTextForTracking,
        jobFormCategoryId: this.state.jobFormCategoryId,
        jobFormPostcode: this.state.jobFormPostcode,
        jobFormCity: this.state.jobFormCity,
        jobFormCategoryName: this.state.jobFormCategoryName,
        isLoggedIn: this.state.isLoggedIn.value
      } as TrackJobFormExitedOptions);
      DataDogRumAgent.removeCustomKey(JOB_FORM_OPEN);
      DataDogRumAgent.removeCustomKey(JOB_FORM_CATEGORY_ID);

      if (!isEmpty(currentUser)) {
        initialState = omit(initialState, ['currentUser', 'isLoggedIn']);
      }

      this.featureManagerSubscription?.unsubscribe();
      // Reset state except currentUser
      this.setState(
        initialState,
        // Initialize questions and userAnswers in setState callback
        this.initializeQuestionsAndAnswers
      );
    };

    setPasswordError = (error: string) => {
      this.setState({ passwordError: error });
    };

    updateState = (newState: Partial<jobFormTypes.JobFormState>) => {
      this.setState(newState as jobFormTypes.JobFormState);
    };

    goToNextStep = async ({
      inputType,
      questionId,
      userAnswer,
      hasNextStep
    }: jobFormTypes.GoToNextStepArgs) => {
      const [answerValue, serviceTypeIds] = jobFormHelper.formatUserAnswer(userAnswer, inputType);
      const serviceTypeId = uniq(serviceTypeIds);
      const goToNextQuestion = () => this.traverseJobForm({
        answerValue: answerValue as string,
        inputType,
        questionId,
        userAnswer,
        serviceTypeId
      });

      const wasHandled = await this.questionHandlerService.handleQuestion({
        questionId,
        inputType,
        userAnswer,
        answerValue,
        serviceTypeId,
        hasNextStep,
        goToNextQuestion,
        context: {
          state: this.state,
          registerJobFormQuestionsExperiment: this.registerJobFormQuestionsExperiment,
          updateState: this.updateState,
          jobFormService: this.jobFormService,
          oneflareAnalytics: this.oneflareAnalytics,
          jobFormErrorHandler: this.jobFormErrorHandler,
          handleCreateJob: this.handleCreateJob,
          handleCreatePendingJob: this.handleCreatePendingJob,
          handleCreateUserWithPendingJob: this.handleCreateUserWithPendingJob,
          handleLoggedInJobCreation: this.handleLoggedInJobCreation,
          setPasswordError: this.setPasswordError
        }
      });

      if (!wasHandled) {
        goToNextQuestion();
      }
    };

    goToPreviousStep = ({
      questionId,
      userAnswer,
      inputType
    }: jobFormTypes.GoToPreviousStepArgs) => {
      const {
        currentStep,
        attachments,
        overrideShowBackButton$,
        resetPasswordEmailRequestSent$,
        showLoginWithPasswordStep$,
        isEmailLinkSent$
      } = this.state;

      if (overrideShowBackButton$.value) {
        overrideShowBackButton$.next(false);
      }

      // If the user has clicked the Email me a Login link to login or
      // reset password link, we display a hidden component, which isn't
      // an extra step, so we need to hide it again
      if (isEmailLinkSent$.value || resetPasswordEmailRequestSent$.value) {
        isEmailLinkSent$.next(false);
        showLoginWithPasswordStep$.next(false);
        resetPasswordEmailRequestSent$.next(false);
      } else {
        // If not we go back as usual, navigating through job form questions
        this.setUserAnswers({
          questionId,
          userAnswer,
          inputType,
          attachments
        });
        currentStep.next(currentStep.value - 1);
      }
    };

    private initializeQuestionsAndAnswers = async (categoryId?: string | number, postcode?: string) => {
      const { pageProps } = this.props;
      const { currentUser, originalQuestions, questions$, userAnswers } = this.state;
      const categoryIdFromProps = pageProps?.categoryId;
      const postCodeFromProps = pageProps?.postcode;
      const isLoggedIn = Boolean(currentUser);
      const initialPostcode = postcode ?? postCodeFromProps;
      const initialCategoryId = categoryId ?? categoryIdFromProps;
      const initialQuestions = await this.jobFormService.getQuestions({
        categoryId: initialCategoryId,
        isLoggedIn,
        flag: GetQuestionFlag.INITIAL
      });
      const initialCategoryQuestionAnswer =  { name: '', value: initialCategoryId ?? '' };
      const initialLocationQuestionAnswer = { name: initialPostcode ?? '', value: '' };
      const initializedUserAnswers = jobFormHelper.initializeAllUserAnswers(
        initialCategoryQuestionAnswer,
        initialLocationQuestionAnswer,
        initialQuestions
      );
      const followUpQuestions = jobFormHelper.constructFollowUpQuestions(
        initialQuestions,
        initializedUserAnswers
      );
      originalQuestions.next(initialQuestions);
      questions$.next(followUpQuestions);
      userAnswers.next(initializedUserAnswers);
      return 'questions retrieved';
    };

    private setUserAnswers = ({
      questionId,
      userAnswer,
      inputType,
      attachments
    }: jobFormTypes.SetUserAnswersArgs) => {
      const { originalQuestions, questions$, userAnswers } = this.state;
      if (questionId === CATEGORY_QUESTION_ID) {
        const currentAnswer = (userAnswer as jobFormTypes.AutoCompleteUserAnswer);
        const previousQuestion = userAnswers.value[questionId];
        const previousAnswer = (previousQuestion.userAnswer as jobFormTypes.UserAnswer);
        const previousAnswerValue = previousAnswer.value;
        const currentAnswerValue = currentAnswer.value;
        if (currentAnswerValue !== previousAnswerValue) {
          // If user goes back to cateogry question to make change
          // after having answered some questions, userAnswers needs to be reset.
          const unsetLocationAnswer = { name: '', value: '' };
          const initialJobFormQuestionsOnReset = originalQuestions.value;
          const initializedUserAnswers = jobFormHelper.initializeAllUserAnswers(
            currentAnswer,
            unsetLocationAnswer,
            initialJobFormQuestionsOnReset
          );
          const followUpQuestions = jobFormHelper.constructFollowUpQuestions(
            initialJobFormQuestionsOnReset,
            initializedUserAnswers
          );
          questions$.next(followUpQuestions);
          userAnswers.next(initializedUserAnswers);
        }
      } else {
        const userAnswersCopy = cloneDeep({
          ...userAnswers.value,
          [questionId]: {
            attachments,
            inputType,
            userAnswer
          }
        });
        userAnswers.next(userAnswersCopy as jobFormTypes.UserAnswers);
      }
    };

    traverseJobForm = async ({
      answerValue,
      inputType,
      questionId,
      userAnswer,
      serviceTypeId
    }: jobFormTypes.TraverseJobFormArguments) => {
      const { currentStep, attachments, userAnswers } = this.state;
      const step = currentStep.value;
      await this.setFollowUpQuestions({
        inputType,
        questionId,
        step,
        userAnswer: userAnswer as jobFormTypes.UserAnswer
      });

      this.setUserAnswers({
        questionId,
        userAnswer,
        inputType,
        attachments
      });

      const categoryId = (userAnswers.value[CATEGORY_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value;
      const locationId = (userAnswers.value[LOCATION_QUESTION_ID]?.userAnswer as jobFormTypes.UserAnswer).value;

      if (step === 0) {
        this.trackingHelper.trackJobFormStart({ categoryId, locationId, questionId });
      }

      this.trackingHelper.trackJobFormAnswer({
        step,
        answerValue,
        categoryId,
        locationId,
        questionId,
        serviceTypeId
      });
      const answerText = TrackingHelper.getAnswerValueForTracking(answerValue, questionId, true, true);
      trackJobFormQuestionCompleted(this.oneflareAnalytics, 'withJobFormController:traverseJobForm', {
        jobFormCategoryId: +categoryId,
        jobFormCategoryName: this.state.jobFormCategoryName,
        questionId: +this.state.currentQuestionIdForTracking,
        answerText,
        jobFormStep: step
      } as TrackJobFormQuestionCompletedOptions);

      // increase step to automatically move to next question
      currentStep.next(step + 1);
    };

    private setFollowUpQuestions = async ({
      inputType,
      questionId,
      step,
      userAnswer
    }: jobFormTypes.SetJobFormQuestionsArgs) => {
      const { emailExists, originalQuestions, questions$, overrideShowBackButton$, mobileVerificationRequired } = this.state;
      /**
       * These are questions that are static and declared on Fronted; not coming from backennd
       * i.e. Description, Contact details, Mobile verification, Email, Location, Category
       */
      const notGeneralQuestion = !GENERAL_QUESTION_IDS.includes(questionId);
      switch (true) {
        case questionId === CATEGORY_QUESTION_ID: {
          const { currentUser } = this.state;
          const isLoggedIn = Boolean(currentUser);
          const nextQuestions = await this.jobFormService.getQuestions({
            categoryId: userAnswer.value,
            isLoggedIn
          });
          originalQuestions.next(nextQuestions);
          break;
        }
        case questionId === EMAIL_QUESTION_ID && emailExists: {
          // If email exists, replace default question Contact details with Mobile verification
          const nextQuestions = questions$.value.map((item) =>
            item.id === CONTACT_DETAILS_ID
              ? MOBILE_VERIFICATION_QUESTION
                : item);
            questions$.next(nextQuestions);
          break;
        }
        case questionId === CONTACT_DETAILS_ID && mobileVerificationRequired: {
          // If current step is the contact details step and mobile verification is required, replace default question Contact details with Mobile verification
          const nextQuestions = [...questions$.value, MOBILE_VERIFICATION_QUESTION];
          overrideShowBackButton$.next(true);
          questions$.next(nextQuestions);
          break;
        }
        case notGeneralQuestion: {
          // Set next question based on user answer, which includes `nextQuestionId`
          const nextQuestion = jobFormHelper.getNextQuestion({
            inputType,
            userAnswer,
            questions: originalQuestions.value
          });

          if (!nextQuestion) {
            const questionsAnswered = questions$.value.slice(0, step + 1);
            const questionsLeft = questions$.value.slice(step + 1);
            const generalQuestionsLeft = questionsLeft.filter(
              ({ id }) => GENERAL_QUESTION_IDS.includes(String(id))
            );
            questions$.next([
              ...questionsAnswered,
              ...generalQuestionsLeft
            ]);
            break;
          }

          const questionsCopy = [...questions$.value];
          const nextQuestionIsAGeneralQuestion = GENERAL_QUESTION_IDS.includes(`${questionsCopy[step + 1].id}`);
          let deleteCount = 1;

          if (nextQuestionIsAGeneralQuestion) {
            deleteCount = 0;
          }

          questionsCopy.splice(step + 1, deleteCount, nextQuestion);
          questions$.next(questionsCopy);
          break;
        }
        default:
          break;
      }
    };

    handleCreateUserWithPendingJob = debounce(async ({
      name,
      phone,
      goToNextQuestion
    }: jobFormTypes.HandleCreateUserWithJobArgs) => {
      const { marketingConsentConfirmed, pendingJobUuid$, jobFormFailureListener$, isLoading$, userCreated } = this.state;
      isLoading$.next(true);
      this.setState({ fullName: name, phone, marketingConsentConfirmed });
      jobFormFailureListener$.next({ failed: false });

      try {
        const userArguments = {
          name,
          pendingJobUuid: pendingJobUuid$.value,
          marketingConsentConfirmed
        };

        let response: { jobId: number; redirectUrl: string; mobileVerificationRequired: boolean } | {
          pendingJobUuid: string;
          mobileVerificationRequired: boolean;
        };

        // handles updating a new user if a user is coming back from the mobile verification step
        if (userCreated) {
          const { email } = this.state.createPendingJobAttrs;
          response = await this.jobFormService.updatePendingJob({ ...userArguments, email, mobile: phone }) as {
            pendingJobUuid: string;
            mobileVerificationRequired: boolean;
          };
        } else {
        // handles creating a new user if a user is coming from the contact details step
          response = await this.jobFormService.createUserWithPendingJob({ ...userArguments, phone});
        }

        // Redirect to My Jobs & auto log in
        if (response.mobileVerificationRequired) {
          const { email } = this.state.createPendingJobAttrs;
          this.setState({ mobileVerificationRequired: true, userCreated: true });
          await this.handleSendMobileCode(email, false);
          this.trackingHelper.trackInteraction('mobileVerificationRequired');
          isLoading$.next(false);
          goToNextQuestion();
          return;
        }

        if ('jobId' in response) {
          const jobId = response.jobId;
          const redirectUrl = response.redirectUrl ? `${JOB_FORM_URL}${response.redirectUrl}` : null;
          this.handlePostComplete(jobId, redirectUrl, CONTACT_DETAILS_ID);
        }
      } catch (exception) {
        isLoading$.next(false);
        this.jobFormErrorHandler.handleError(exception, 'Oneflare | HOC withJobFormController | CREATE_JOB mutation');
      }
    }, 300);

    handleCreateJob = debounce(async ({
      questionId,
      jobData
    }: jobFormTypes.HandleCreateJobArgs) => {
      const { isLoading$, currentUser, userAnswers, pendingJobUuid$, createPendingJobAttrs } = this.state;
      // set loading state to true
      isLoading$.next(true);

      await this.jobFormErrorHandler.wrapWithErrorHandling({
        operation: async () => {
          const categoryId = (userAnswers.value[CATEGORY_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value ?? createPendingJobAttrs.categoryId;
          const createJobVariables = {} as jobFormTypes.CreateJobAttrs;
          // if logged in, use user from context
          if (!isEmpty(currentUser?.user)) {
            createJobVariables.user = pick(currentUser.user, ['email', 'name', 'phone']);
            createJobVariables.jobData = jobData;
            createJobVariables.categoryId = +categoryId;
          } else {
            const { email, jobData } = createPendingJobAttrs;
            const { firstName: name, phone } = this.state;
            createJobVariables.pendingJobUuid = pendingJobUuid$.value;
            createJobVariables.categoryId = +categoryId;
            createJobVariables.jobData = jobData;
            createJobVariables.user = {
              email,
              ...(name && { name }),
              ...(phone && { phone })
            };
          }
          const response = await this.jobFormService.createJob(createJobVariables);
          const { createJob } = cloneDeep(response) as CreateJobReponse;
          const jobId = createJob.id;
          const redirectUrl = createJob.redirectUrl;
          this.handlePostComplete(jobId, redirectUrl, questionId);
        },
        context: 'Oneflare | HOC withJobFormController | CREATE_JOB mutation',
        onCatch: () => {
          isLoading$.next(false);
        }
      });
    }, 300);

    handleCreatePendingJob = async ({
      email,
      emailExists = false,
      phoneExists = false,
      userAnswers
    }: jobFormTypes.HandleCreatePendingJobArgs) => {
      const { isLoading$, pendingJobUuid$, questions$ } = this.state;
      isLoading$.next(true);
      // Create pending job
      const formattedUserAnswersAsPendingJobAttrs = jobFormHelper.formatAllUserAnswers({
        email,
        questions: questions$.value,
        userAnswers: userAnswers.value
      });
      const { pendingJobUuid } = await this.jobFormService.createPendingJob(formattedUserAnswersAsPendingJobAttrs);
      pendingJobUuid$.next(pendingJobUuid);
      this.setState({ createPendingJobAttrs: formattedUserAnswersAsPendingJobAttrs });
      // send mobile verification code if existing user
      if (emailExists && phoneExists) {
        await this.handleSendMobileCode(email, false);
      }
      isLoading$.next(false);
    };

    validatePhone = async (phone: string) => {
      try {
        const { valid, message } = await this.jobFormService.validatePhone(phone);
        return { valid, message };
      } catch (exception) {
        DataDogRumAgent.addRumError(exception, 'Oneflare | HOC withJobFormController | validatePhone mutation');
        return { valid: false, message: exception.message || 'Something went wrong! Please try again later.' };
      }
    };

    private handlePostComplete = (jobId: number, redirectUrl: string, questionId: string) => {
      if (!jobId && !redirectUrl) throw TypeError('jobId and redirectUrl are missing');
      const { postedJob$, createPendingJobAttrs, userAnswers, external, currentStep, showSuccessScreen$ } = this.state;
      const { redirectOnSuccess, isIframe } = external.value;
      const step = currentStep.value;
      const categoryId = (userAnswers.value[CATEGORY_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value as number ?? createPendingJobAttrs.categoryId;
      const locationId = (userAnswers.value[LOCATION_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer).value;
      const trackingArguments = {
        step,
        jobId,
        categoryId,
        questionId,
        locationId,
        createPendingJobAttrs
      };

      this.trackFormComplete(trackingArguments);

      // fake delay to allow tracking to complete
      setTimeout(() => {
        if (isIframe) {
          if (redirectOnSuccess) {
            window.parent.location.replace(redirectUrl);
            return;
          }
          // this is purely for iframe use case where we display a success screen as the last step
          postedJob$.next({ id: jobId, redirectUrl });
          showSuccessScreen$.next(true);
          return;
        }
        window.location.replace(redirectUrl);
      }, 1500);
    };

    handleLoggedInJobCreation = async ({
      userAnswer,
      questionId,
      answerValue,
      userAnswers,
      serviceTypeId
    }: jobFormTypes.HandleLoggedInJobCreationArgs) => {
      const { questions$, currentStep, currentUser: { user }, attachments } = this.state;
      const step = currentStep.value;
      const { email } = user;
      const currentAnswer = userAnswer as jobFormTypes.UserAnswer;
      const locationAnswer = userAnswers.value[LOCATION_QUESTION_ID].userAnswer as jobFormTypes.UserAnswer;
      const locationId = locationAnswer.value;
      const formattedUserAnswers = jobFormHelper.formatAllUserAnswers({
        email,
        questions: questions$.value,
        userAnswers: userAnswers.value
      });

      const { categoryId, jobData } = formattedUserAnswers;

      jobData.job.attachments = [];

      if (!isEmpty(attachments)) {
        jobData.job.attachments = attachments.map(({ thumb }) => thumb);
      }

      jobData.job.description = currentAnswer.value as string;

      this.trackingHelper.trackJobFormAnswer({
        step,
        answerValue,
        categoryId,
        locationId,
        questionId,
        serviceTypeId
      });

      await this.handleCreateJob({ questionId, jobData });
    };

    private trackFormComplete = (jobAttributes: jobFormTypes.TrackCompleteArgs) => {
      // snowplow tracking
      this.trackingHelper.trackJobFormComplete({...jobAttributes });

      // segment tracking
      trackJobFormCompleted(this.oneflareAnalytics, 'withJobFormController:trackFormComplete', {
        isLoggedIn: this.state.isLoggedIn.value,
        jobId: jobAttributes.jobId.toString(),
        jobFormCategoryId: jobAttributes.categoryId,
        jobFormCategoryName: this.state.jobFormCategoryName,
        jobFormPostcode: this.state.jobFormPostcode,
        jobFormCity: this.state.jobFormCity
      } as TrackJobFormCompletedOptions);
    };

    trackQuestionInitiated = ({
      questionId,
      questionText,
      jobFormStep
    }: jobFormTypes.TrackQuestionInitiatedArgs) => {
      this.setState({
        currentQuestionIdForTracking: String(questionId),
        currrentQuestionTextForTracking: questionText
      });
      trackJobFormQuestionInitiated(this.oneflareAnalytics, 'withJobFormController:trackQuestionInitiated', {
        jobFormCategoryId: this.state.jobFormCategoryId,
        jobFormCategoryName: this.state.jobFormCategoryName,
        questionId,
        jobFormStep,
        questionText
      } as TrackJobFormQuestionInitiatedOptions);
    };

    registerJobFormQuestionsExperiment = (categoryName: string) => {
      if (!categoryName) return;
      // Create and store the experiment service when feature manager is available
      this.featureManagerSubscription = getClientFeatureManager().pipe(
        distinctUntilChanged(isEqual),
        // Create and store the experiment service when feature manager is available
        tap(featureManager => {
          this.jobFormQuestionsExperimentService = new JobFormQuestionsExperimentService(featureManager);
        }),
        // Only continue the pipe when we have both requirements
        filter(() => Boolean(this.jobFormQuestionsExperimentService) && Boolean(categoryName)),
        // Apply experiments to questions
        tap(() => {
          if (this.jobFormQuestionsExperimentService) {
            return this.jobFormQuestionsExperimentService.applyExperimentsToQuestions(
              this.state.questions$, 
              categoryName,
              this.state.userAnswers
            );
          }
        })
      ).subscribe();
    };

    trackingHelper: TrackingHelper;
    jobFormErrorHandler: JobFormErrorHandler;
    oneflareAnalytics: OneflareAnalytics;
    jobFormService: JobFormService;
    private DOMAIN: 'oneflare' | 'wedding';
    private jobFormQuestionsExperimentService: JobFormQuestionsExperimentService;
    private featureManagerSubscription: Subscription;
    private questionHandlerService: QuestionHandlerService;

    render() {
      const props = { ...this.props };
      return (
        <JobFormControllerContext.Provider value={{ controller: this }}>
          <WrappedComponent {...props}>
            <JobForm />
          </WrappedComponent>
        </JobFormControllerContext.Provider>
      );
    }
  };
};

export default withJobFormController;
