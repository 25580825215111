import {
  trackJobFormCategorySet,
  trackJobFormContactDetailsSet,
  trackJobFormDateSet,
  TrackJobFormDateSetOptions,
  trackJobFormDetailsSet,
  TrackJobFormDetailsSetOptions,
  trackJobFormEmailSet,
  trackJobFormLocationSet,
  type TrackJobFormCategorySetOptions,
  type TrackJobFormLocationSetOptions
} from '@oneflare/web-analytics';
import isEmpty from 'lodash/isEmpty';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { isNestedObject } from 'shared/utils/helpers';
import {
  AutoCompleteUserAnswer,
  CheckboxUserAnswer,
  ContactDetailAnswer,
  IJobFormController,
  InputComponentUserAnswer,
  JobFormInputType,
  SchedulerUserAnswer
} from 'types/oneflare.com.au/jobForm';

import {
  CATEGORY_QUESTION_ID,
  DESCRIPTION_QUESTION_ID,
  EMAIL_QUESTION_ID,
  JOB_FORM_CATEGORY_ID,
  LOCATION_QUESTION_ID,
  MOBILE_VERIFICATION_ID
} from './constants';
import JobFormHelper from './jobFormHelper';

export type QuestionHandlerServiceContext = Pick<
  IJobFormController,
  | 'oneflareAnalytics'
  | 'registerJobFormQuestionsExperiment'
  | 'state'
  | 'jobFormService'
  | 'jobFormErrorHandler'
  | 'handleCreateUserWithPendingJob'
  | 'updateState'
  | 'handleCreateJob'
  | 'handleLoggedInJobCreation'
  | 'handleCreatePendingJob'
  | 'setPasswordError'
>;

export type QuestionHandlerArgs = {
  questionId: string;
  inputType: JobFormInputType;
  userAnswer: InputComponentUserAnswer;
  answerValue: string | number | string[];
  serviceTypeId: number[];
  hasNextStep: boolean;
  goToNextQuestion: () => Promise<void>;
  context: QuestionHandlerServiceContext;
};

interface QuestionHandler {
  canHandle(
    questionId: string,
    inputType: string,
    context: QuestionHandlerServiceContext
  ): boolean;
  handle(args: QuestionHandlerArgs): Promise<void>;
}

class CategoryQuestionHandler implements QuestionHandler {
  canHandle(questionId: string) {
    return questionId === CATEGORY_QUESTION_ID;
  }

  async handle(args: QuestionHandlerArgs) {
    const { goToNextQuestion, context } = args;
    const {
      oneflareAnalytics,
      registerJobFormQuestionsExperiment,
      state: { jobFormCategoryId, jobFormCategoryName, isLoggedIn }
    } = context;

    registerJobFormQuestionsExperiment(jobFormCategoryName);
    DataDogRumAgent.addCustomKey(JOB_FORM_CATEGORY_ID, jobFormCategoryId);
    trackJobFormCategorySet(
      oneflareAnalytics,
      'withJobFormController:goToNextStep',
      {
        jobFormCategoryId,
        jobFormCategoryName,
        isLoggedIn: isLoggedIn.value
      } as TrackJobFormCategorySetOptions
    );
    
    await goToNextQuestion();
  }
}

class LocationQuestionHandler implements QuestionHandler {
  canHandle(questionId: string) {
    return questionId === LOCATION_QUESTION_ID;
  }

  async handle(args: QuestionHandlerArgs) {
    const { goToNextQuestion, context } = args;
    const {
      oneflareAnalytics,
      state: { isLoggedIn, jobFormPostcode, jobFormCity, questions$ }
    } = context;

    // Question with id '2' gets pre-filled with state name from answer to location question
    const hasStateQuestion = questions$.value.some(
      (question) => question.id === '2'
    );
    if (hasStateQuestion) {
      const autocompleteAnswer = args.userAnswer as AutoCompleteUserAnswer;
      const {
        state: { userAnswers }
      } = context;
      const stateName = JobFormHelper.getGeographicalState(
        autocompleteAnswer.name
      );
      userAnswers.next({
        ...userAnswers.value,
        2: {
          ...userAnswers.value['2'],
          userAnswer: { serviceTypeId: null, value: stateName }
        }
      });
    }

    trackJobFormLocationSet(
      oneflareAnalytics,
      'withJobFormController:goToNextStep',
      {
        jobFormPostcode,
        jobFormCity,
        isLoggedIn: isLoggedIn.value
      } as TrackJobFormLocationSetOptions
    );

    await goToNextQuestion();
  }
}

class EmailQuestionHandler implements QuestionHandler {
  canHandle(questionId: string) {
    return questionId === EMAIL_QUESTION_ID;
  }

  async handle(args: QuestionHandlerArgs) {
    const { userAnswer, goToNextQuestion, context } = args;
    const {
      jobFormService,
      jobFormErrorHandler,
      handleCreatePendingJob,
      updateState,
      state,
      oneflareAnalytics,
      state: { userAnswers }
    } = context;
    await jobFormErrorHandler.wrapWithErrorHandling({
      operation: async () => {
        const email = userAnswer as string;
        const {
          validEmail,
          emailExists,
          phoneExists,
          firstName,
          mobileVerificationRequired
        } = await jobFormService.validateEmail(email);
        // Update state with email validation results
        updateState({
          emailExists,
          phoneExists,
          mobileVerificationRequired,
          firstName
        });
        if (!validEmail && !emailExists) {
          const { emailState } = state;
          emailState.next({
            errorMessage: 'Please enter a valid email address'
          });
          return;
        }
        // Create pending job with email
        await handleCreatePendingJob({
          email,
          userAnswers,
          emailExists,
          phoneExists
        });
        trackJobFormEmailSet(
          oneflareAnalytics,
          'withJobFormController:goToNextStep'
        );
        await goToNextQuestion();
      },
      context: 'Oneflare | HOC withJobFormController | CREATE_PENDING_JOB mutation'
    });
  }
}

class ContactDetailsQuestionHandler implements QuestionHandler {
  canHandle(questionId: string) {
    return questionId === 'contact-details';
  }

  async handle(args: QuestionHandlerArgs) {
    const { questionId, goToNextQuestion, context } = args;
    const { oneflareAnalytics, handleCreateUserWithPendingJob } = context;
    const userAnswer = args.userAnswer as ContactDetailAnswer;
    const { name, phone } = userAnswer;
    trackJobFormContactDetailsSet(
      oneflareAnalytics,
      'withJobFormController:goToNextStep'
    );
    await handleCreateUserWithPendingJob({
      questionId,
      name,
      phone,
      goToNextQuestion
    });
  }
}

class MobileVerificationHandler implements QuestionHandler {
  canHandle(questionId: string) {
    return questionId === MOBILE_VERIFICATION_ID;
  }

  async handle(args: QuestionHandlerArgs) {
    const { questionId, userAnswer, context } = args;
    const {
      state: { showLoginWithPasswordStep$, createPendingJobAttrs },
      jobFormService,
      jobFormErrorHandler,
      setPasswordError,
      handleCreateJob
    } = context;

    if (showLoginWithPasswordStep$.value) {
      await jobFormErrorHandler.wrapWithErrorHandling({
        operation: async () => {
          const { email } = createPendingJobAttrs;
          const password = userAnswer as string;
          const userId = await jobFormService.logInWithPassword({
            email,
            password
          });

          if (!userId || typeof userId === 'undefined') {
            setPasswordError(
              'The password you have entered is incorrect. Please try again.'
            );
          } else {
            await handleCreateJob({ questionId });
          }
        },
        context: 'Oneflare | HOC withJobFormController | LOG_IN_WITH_PASSWORD mutation'
      });
    }
  }
}

class DescriptionQuestionHandler implements QuestionHandler {
  canHandle(questionId: string, _inputType: string) {
    return questionId === DESCRIPTION_QUESTION_ID;
  }

  async handle({
    questionId,
    userAnswer,
    answerValue,
    serviceTypeId,
    hasNextStep,
    goToNextQuestion,
    context
  }: QuestionHandlerArgs) {
    const {
      oneflareAnalytics,
      handleLoggedInJobCreation,
      state: { userAnswers, attachments }
    } = context;

    // Track details
    trackJobFormDetailsSet(
      oneflareAnalytics,
      'withJobFormController:goToNextStep',
      {
        furtherDetailsAdded: String(answerValue).length > 0,
        photosAdded: !isEmpty(attachments)
      } as TrackJobFormDetailsSetOptions
    );

    // If user is on description step and there are no more steps, assume user is logged in
    if (!hasNextStep) {
      await handleLoggedInJobCreation({
        userAnswer,
        questionId,
        answerValue: String(answerValue),
        userAnswers,
        serviceTypeId
      });
    } else {
      await goToNextQuestion();
    }
  }
}

type DatepickerHandlerArgs = {
  goToNextQuestion: () => void;
  context: QuestionHandlerServiceContext;
};

class DatepickerHandler implements QuestionHandler {
  canHandle(_questionId: string, inputType: string) {
    return inputType === 'Datepicker';
  }

  async handle({ goToNextQuestion, context }: DatepickerHandlerArgs) {
    const { oneflareAnalytics } = context;
    trackJobFormDateSet(
      oneflareAnalytics,
      'withJobFormController:goToNextStep',
      {
        dateOption: 'Specific date'
      } as TrackJobFormDateSetOptions
    );
    await goToNextQuestion();
  }
}

class SchedulerHandler implements QuestionHandler {
  canHandle(_questionId: string, inputType: string) {
    return inputType === 'Scheduler';
  }

  async handle(args: QuestionHandlerArgs) {
    const { goToNextQuestion, context } = args;
    const userAnswer = args.userAnswer as SchedulerUserAnswer;
    const { oneflareAnalytics } = context;
    const { value: dateOption } = userAnswer;
    trackJobFormDateSet(
      oneflareAnalytics,
      'withJobFormController:goToNextStep',
      {
        dateOption
      } as TrackJobFormDateSetOptions
    );
    await goToNextQuestion();
  }
}

class DefaultQuestionHandler implements QuestionHandler {
  canHandle() {
    return true;
  }

  async handle({ inputType, userAnswer, goToNextQuestion }: QuestionHandlerArgs) {
    if (inputType == 'Radio' || inputType == 'Checkbox') {
      const nextUrl = this.getNextUrl(userAnswer);
      if (nextUrl) {
        // If the form is embedded, redirect the parent window
        if (window !== window.parent) {
          window.parent.location.href = nextUrl;
        } else {
          window.location.href = nextUrl;
        }
        return;
      }
    }
    await goToNextQuestion();
  }

  private getNextUrl (answer: InputComponentUserAnswer): string | undefined {
    if (isNestedObject(answer)) {
      return Object.values(answer as CheckboxUserAnswer).find(item => item?.checked && item?.nextUrl)?.nextUrl;
    } else if (typeof answer === 'object' && 'nextUrl' in answer) {
      return typeof answer.nextUrl === 'string' ? answer.nextUrl : undefined;
    }
    return undefined;
  }
}

export class QuestionHandlerService {
  private handlers: QuestionHandler[] = [];

  constructor() {
    this.registerHandlers();
  }

  private registerHandlers() {
    this.handlers = [
      new CategoryQuestionHandler(),
      new LocationQuestionHandler(),
      new EmailQuestionHandler(),
      new ContactDetailsQuestionHandler(),
      new MobileVerificationHandler(),
      new DescriptionQuestionHandler(),
      new DatepickerHandler(),
      new SchedulerHandler(),
      new DefaultQuestionHandler()
    ];
  }

  async handleQuestion(args: QuestionHandlerArgs) {
    for (const handler of this.handlers) {
      if (handler.canHandle(args.questionId, args.inputType, args.context)) {
        await handler.handle(args);
        return true;
      }
    }
    return false;
  }
}
