import { AirtaskerRegion } from '@airtasker/kmp-fundamental';
import { AnalyticsBrowser, User } from '@segment/analytics-next';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { getCookieWithDefaultValue, setCookie } from 'lib/utils/cookieUtils';
import { publicRuntimeConfig } from 'lib/utils/Environment';

import { AnonymousUserIdClientAdapter } from './anonymousUserId/anonymousUserIdClientAdapter';

interface TrackerPageData {
  path: string;
  referrer: string;
  search: string;
  title: string;
  url: string;
}

// maps from cookie names to segment properties
const segmentCookieName = 'ajs_anonymous_id';
const segmentCookieDomain = 'oneflare.com';
const segmentCookieExpiryMillis = 1 * 24 * 60 * 60 * 1000; // 1 day

export interface Analytics {
  track(options: any): void;
  getPageData: () => TrackerPageData;
  getTimeZone: () => string;
  getRegionCode: () => string;
  segmentPageEvent?: () => void;
}

class AnalyticsUserManager {
  private analyticsUser: User;
  private anonIdProvider: AnonymousUserIdClientAdapter;

  constructor(private tracker: AnalyticsBrowser) {
    this.anonIdProvider = new AnonymousUserIdClientAdapter();
  }

  async identify(userId?: string): Promise<void> {
    try {
      this.analyticsUser = await this.tracker.user();
      this.syncAnyonymousId();
      if (userId) {
        await this.tracker.identify(userId);
      }
    } catch (error) {
      DataDogRumAgent.addRumError(error, 'Oneflare | AnalyticsUserManager | identify');
    }
  }

  syncAnyonymousId() {
    let oneflareAnonId = this.anonIdProvider.getId();
    const segmentAnonId = this.getSegmentAnonId();

    if (!oneflareAnonId) {
      oneflareAnonId = this.anonIdProvider.generateId();
      console.info(
        `oneflare anonId is not available, using generated id '${oneflareAnonId}'`
      );
      this.anonIdProvider.setId(oneflareAnonId);
    }

    if (segmentAnonId !== oneflareAnonId) {
      console.debug(
        `syncing segmentAnonId '${segmentAnonId}' to oneflare anonId '${oneflareAnonId}'`
      );
      this.setAnonymousId(oneflareAnonId);
    }
  }

  private setAnonymousId(anonymousId: string) {
    if (this.isSegmentAnalyticsJsLoaded()) {
      this.tracker.setAnonymousId(anonymousId);
    } else {
      console.debug(
        'segment analytics sdk has not loaded, setting the segment cookie directly'
      );

      setCookie(
        segmentCookieName,
        JSON.stringify(anonymousId),
        new Date(Date.now() + segmentCookieExpiryMillis),
        segmentCookieDomain
      );
    }
  }

  private getSegmentAnonId(): string {
    if (this.isSegmentAnalyticsJsLoaded()) {
      return this.analyticsUser.anonymousId() ?? '';
    }
    console.debug(
      'segment analytics sdk has not loaded, accessing the segment cookie directly'
    );
    const segmentCookie = getCookieWithDefaultValue(segmentCookieName);
    try {
      return JSON.parse(segmentCookie);
    } catch (error) {
      console.info('Analytics 2.0 cookie detected');
      return segmentCookie ?? '';
    }
  }

  private isSegmentAnalyticsJsLoaded() {
    // The user function is not available before the analytics.js library is loaded
    return typeof this.analyticsUser !== 'undefined';
  }
}

interface MappedData {
  event_name?: string;
  timezone?: string;
  properties?: any[];
  conversion_event?: boolean;
}

/**
 * Oneflare Analytics class
 * This class is used to track events and page views using Segment Analytics
 *
 * See: https://segment.com/docs/connections/spec/ for more methods and options to extend this class
 */
class OneflareAnalytics implements Analytics {
  tracker: AnalyticsBrowser;
  static instance: OneflareAnalytics;
  analyticsUserManager: AnalyticsUserManager;

  constructor(userId?: string) {
    if (OneflareAnalytics.instance) {
      return OneflareAnalytics.instance;
    }

    if (typeof window == 'undefined') {
      console.error('Oneflare Analytics can only be initialized on the client side');
    } else {
      this.initBrowserAgent(userId);
    }
  }

  private initBrowserAgent(userId?: string) {
    this.tracker = AnalyticsBrowser.load({
      cdnURL: publicRuntimeConfig.NEXT_PUBLIC_SEGMENT_CDN_URL!,
      writeKey: publicRuntimeConfig.NEXT_PUBLIC_SEGMENT_WRITE_KEY!
    });
    this.analyticsUserManager = new AnalyticsUserManager(this.tracker);
    this.analyticsUserManager.identify(userId);
    OneflareAnalytics.instance = this;
  }

  track(mappedData: MappedData) {
    const { event_name: eventName, timezone, ...properties } = mappedData;
    const customProperties = {
      ...properties
    };

    const options: { [k: string]: any } = {};

    options.context = {
      ip: '0.0.0.0',
      timezone
    };

    options.integrations = {};
    if (mappedData.conversion_event) {
      options.integrations = { All: true };
    }

    this.analyticsUserManager.syncAnyonymousId();
    try {
      this.tracker.track(eventName, customProperties, options);
    } catch (error) {
      DataDogRumAgent.addRumError(error, 'Oneflare | OneflareAnalytics | track');
    }
  }

  getPageData() {
    return {
      path: window.location.pathname,
      referrer: window.document.referrer,
      search: window.location.search,
      title: window.document.title,
      url: `${window.location.origin}${window.location.pathname}`
    };
  }

  getTimeZone() {
    return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getRegionCode() {
    return AirtaskerRegion.AUSTRALIA.airtaskerRegionCode;
  }

  segmentPageEvent() {
    // don't track if current page is `/get-free-quotes`
    if (window.location.pathname === '/get-free-quotes') {
      return;
    }
    this.analyticsUserManager.syncAnyonymousId();
    try {
      this.tracker.page();
    } catch (error) {
      DataDogRumAgent.addRumError(error, 'Oneflare | OneflareAnalytics | segmentPageEvent');
    }
  }
}

export { OneflareAnalytics };
