import { AirtaskerRegion } from '@airtasker/kmp-fundamental';

import { AnonymousUserIdClientAdapter } from 'lib/analytics/anonymousUserId/anonymousUserIdClientAdapter';
import type { OneflareAnalytics } from 'lib/analytics/oneflareAnalytics';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { getFeatureManagementEnvironmentConfig } from 'lib/utils/Environment';

import { setClientFeatureManager, UserContext } from './featureManager';
import { OneflareFeatureManagerClient } from './oneflareFeatureManagerClient';
import {
  getFeatureManagementEnvironment,
  isFeatureManagementInTestingMode
} from './utils';

const REGION_CODE = AirtaskerRegion.AUSTRALIA.airtaskerRegionCode;

const setupOneflareFeatureManagerClient = (
  tracker: OneflareAnalytics,
  userContext: UserContext
) => {
  const apiKey = getFeatureManagementEnvironmentConfig().clientSideApiKey;
  const environment = getFeatureManagementEnvironment();
  const testingMode = isFeatureManagementInTestingMode();
  const featureManager = new OneflareFeatureManagerClient(
    apiKey,
    environment,
    testingMode,
    tracker,
    userContext
  );

  const startTime = Date.now();
  const waitTimeMs = 3000;
  const interval = window.setInterval(() => {
    if (featureManager.isSdkInitialised()) {
      setClientFeatureManager(featureManager);
      window.clearInterval(interval);
      return;
    }

    const currentTime = Date.now();
    if (currentTime - startTime > waitTimeMs) {
      DataDogRumAgent.addRumUserAction('feature-manager-initialisee-timeout', {
        waitTimeMs
      });
    }
  }, 75);
};

const setupOneflareFeatureManagerClientAuthenticated = (
  tracker: OneflareAnalytics,
  userId: string
) => {
  setupOneflareFeatureManagerClient(tracker, {
    isAuthenticated: true,
    userId,
    regionCode: REGION_CODE
  });
};

const setupOneflareFeatureManagerClientUnauthenticated = (
  tracker: OneflareAnalytics
) => {
  const anonymousUserId = new AnonymousUserIdClientAdapter().findOrCreateId({
    onNotFound: () => {
      // do nothing
    }
  });
  setupOneflareFeatureManagerClient(tracker, {
    isAuthenticated: false,
    userId: anonymousUserId,
    regionCode: REGION_CODE
  });
};

export {
  setupOneflareFeatureManagerClientAuthenticated,
  setupOneflareFeatureManagerClientUnauthenticated,
  setupOneflareFeatureManagerClient as testSetupOneflareFeatureManagerClient
};
