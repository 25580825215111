import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import ApolloRun from 'lib/utils/apolloRun';
import {
  CREATE_USER_WITH_PENDING_JOB,
  type CreateUserWithPendingResponse
} from 'mutations/shared/createUserWithPendingJob';
import {
  CREATE_JOB,
  type CreateJobReponse,
  CREATE_PENDING_JOB,
  type CreatePendingJobResponse,
  CREATE_USER_SESSIONS,
  type CreateUserSessionsResponse,
  RESET_PASSWORD,
  type SendPasswordResetResponse,
  SEND_EMAIL_MAGIC_LINK,
  type SendNotificationPendingJobResponse,
  VERIFY_MOBILE_AUTHENTICATION_TOKEN,
  type VerifyMobileResponse,
  UPDATE_PENDING_JOB,
  type UpdatePendingJobResponse
} from 'mutations/shared/jobForm';
import {
  SEND_SMS,
  type SendMobileAuthenticationTokenResponse
} from 'mutations/shared/mobileVerification';
import {
  EMAIL_VALIDATION,
  type ValidateEmailResponse
} from 'queries/oneflare.com.au/emailValidation';
import { GET_JOB_FORM_QUESTIONS } from 'queries/oneflare.com.au/jobForm';
import {
  PHONE_VALIDATION,
  type PhoneValidationResponse
} from 'queries/oneflare.com.au/phoneValidations';
import { CATEGORY, type CategoryResponse } from 'queries/shared/category';
import { GET_USER, GetCurrentUserResponse } from 'queries/shared/user';
import { indefiniteArticleCheck } from 'shared/utils/helpers';
import { GraphQLServerError } from 'types/oneflare.com.au/apiErrors';
import type {
  Id,
  CreateJobAttrs,
  CreatePendingJobAttrs,
  LogInWithPasswordInput,
  JobFormQuestions,
  JobFormQuestion
} from 'types/oneflare.com.au/jobForm';

import { SMSCodeError } from '../errors/smsCodeError';

import { CATEGORY_QUESTION, LOCATION_QUESTION } from './constants';
import jobFormHelper, { GetQuestionFlag } from './jobFormHelper';
import TrackingHelper from './trackingHelper';

export type UpdatePendingJobVariables = {
  pendingJobUuid: string;
  email: string;
  name: string;
  mobile: string;
  marketingConsentConfirmed: boolean;
};

export type CreateUserWithPendingJobVariables =
  | {
      pendingJobUuid: string;
      name: string;
      phone: string;
      marketingConsentConfirmed?: boolean;
    }
  | {
      id: number;
      name: string;
      phone: string;
      marketingConsentConfirmed?: boolean;
    };

class JobFormService {
  client: ApolloRun;
  constructor() {
    this.client = new ApolloRun();
  }
  getCurrentUser = async () => {
    const [response, errors] =
      await this.client.query<GetCurrentUserResponse>(GET_USER);

    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | GET_USER query'
      );
      return null;
    }
    return response.currentUser;
  };

  getCategory = async (categoryId: Id) => {
    const [response, errors] = await this.client.query<CategoryResponse>(
      CATEGORY,
      { identifier: categoryId }
    );
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | GET_CATEGORY query'
      );
      return null;
    }
    return response.category;
  };

  getQuestions = async (args: {
    categoryId: Id,
    isLoggedIn: boolean,
    flag?: GetQuestionFlag
  }): Promise<JobFormQuestions> => {
    const { categoryId, isLoggedIn, flag } = args;
    if (!categoryId) return [CATEGORY_QUESTION];
    const locationQuestion = await this.constructLocationQuestion(categoryId);
    const jobFormQuestions = await this.getJobFormQuestions(categoryId);
    const { questions } = jobFormHelper.formatJobFormQuestions({
      locationQuestion,
      jobFormQuestions,
      isLoggedIn,
      flag
    });
    return questions;
  };

  getJobFormQuestions = async (categoryId: Id) => {
    const [response, errors] = await this.client.query<{
      jobFormQuestions: JobFormQuestions;
    }>(GET_JOB_FORM_QUESTIONS, { categoryId, jobFormV2: false });
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | GET_JOB_FORM_QUESTIONS query'
      );
      return [] as JobFormQuestions;
    }
    return response.jobFormQuestions;
  };

  constructLocationQuestion = async (categoryId: Id) => {
    const category = await this.getCategory(categoryId);
    const formattedCategoryName = indefiniteArticleCheck(
      category?.singular?.toLowerCase()
    );
    return {
      ...LOCATION_QUESTION,
      question: `Where do you need ${formattedCategoryName}?`
    } as JobFormQuestion;
  };

  validateEmail = async (
    email: string
  ): Promise<ValidateEmailResponse['validateEmail']> => {
    const [response, errors] = await this.client.query<ValidateEmailResponse>(
      EMAIL_VALIDATION,
      { email }
    );
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | EMAIL_VALIDATION query'
      );
      throw errors;
    }
    return response.validateEmail;
  };

  validatePhone = async (phone: string) => {
    const [response, errors] = await this.client.query<PhoneValidationResponse>(
      PHONE_VALIDATION,
      { phone }
    );
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | PHONE_VALIDATION query'
      );
      throw errors;
    }
    return response.validatePhone;
  };

  createPendingJob = async (attributes: CreatePendingJobAttrs) => {
    const paramsToObject = (entries) => {
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result as {
        gclid: string | undefined;
        dclid: string | undefined;
        msclkid: string | undefined;
        fbclid: string | undefined;
      };
    };

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const objectParams = paramsToObject(urlParams);
    const trackingData = {
      gaId: TrackingHelper.getGAClientId(),
      spId: TrackingHelper.getSnowplowUid(undefined),
      gclid: objectParams.gclid || undefined,
      dclid: objectParams.dclid || undefined,
      msclkid: objectParams.msclkid || undefined,
      fbclid: objectParams.fbclid || undefined
    };

    const variables = Object.assign(attributes, trackingData);
    const [response, errors] =
      await this.client.mutate<CreatePendingJobResponse>(CREATE_PENDING_JOB, {
        attributes: variables
      });

    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | CREATE_PENDING_JOB mutation'
      );
      throw new Error(errors.message, { cause: errors });
    }
    return response.createPendingJob;
  };

  updatePendingJob = async (attributes: UpdatePendingJobVariables) => {
    const [response, errors] = await this.client.mutate<
      UpdatePendingJobResponse,
      UpdatePendingJobVariables
    >(UPDATE_PENDING_JOB, { attributes });
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | UPDATE_PENDING_JOB mutation'
      );
      throw new Error(errors.message, { cause: errors });
    }
    return response.updatePendingJob;
  };

  createUserWithPendingJob = async (
    variables: CreateUserWithPendingJobVariables
  ) => {
    const { name, phone, marketingConsentConfirmed } = variables;
    let id: number;
    let pendingJobUuid: string;
    if ('id' in variables) {
      ({ id } = variables);
    }

    if ('pendingJobUuid' in variables) {
      ({ pendingJobUuid } = variables);
    }

    const [response, errors] =
      await this.client.mutate<CreateUserWithPendingResponse>(
        CREATE_USER_WITH_PENDING_JOB,
        {
          name,
          phone,
          marketingConsentConfirmed,
          ...(id && { id }),
          ...(pendingJobUuid && { pendingJobUuid })
        }
      );

    if (errors) {
      // Send error to RUM agent if not empty, expected types and messages
      const rumErrorArguments: [unknown, string] = [
        errors,
        'Oneflare | HOC withJobFormController | CREATE_JOB mutation'
      ];
      if (!jobFormHelper.canIgnoreError(errors))
        DataDogRumAgent.addRumError(...rumErrorArguments);
      const extractedErrorMessage = jobFormHelper.getErrorMessage(
        errors.message
      );

      // we still want to throw an error regardless for the user to receive feedback
      throw new Error(extractedErrorMessage);
    }

    return response.createUserWithPendingJob;
  };

  createJob = async (attributes: CreateJobAttrs) => {
    const [response, errors] = await this.client.mutate<CreateJobReponse>(
      CREATE_JOB,
      { attributes }
    );
    if (errors) {
      // Send error to RUM agent if not empty, expected types and messages
      const rumErrorArguments: [unknown, string] = [
        errors,
        'Oneflare | HOC withJobFormController | CREATE_JOB mutation'
      ];
      if (!jobFormHelper.canIgnoreError(errors))
        DataDogRumAgent.addRumError(...rumErrorArguments);
      const extractedErrorMessage = jobFormHelper.getErrorMessage(
        errors.message
      );

      // we still want to throw an error regardless for the user to receive feedback
      throw new Error(extractedErrorMessage);
    }
    return response;
  };

  sendMobileCode = async (email: string) => {
    const [response, errors] =
      await this.client.mutate<SendMobileAuthenticationTokenResponse>(
        SEND_SMS,
        { email }
      );
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | SEND_SMS mutation'
      );

      // add a custom handler for exception when sending mobile code to display a more friendly message to user
      throw new SMSCodeError(`Failed to send SMS Code. ${errors.message}`, {
        cause: errors
      });
    }
    return response.sendMobileAuthenticationToken;
  };

  verifyMobileCode = async (
    email: string,
    token: string
  ): Promise<VerifyMobileResponse['verifyMobile']> => {
    const [response, errors] = await this.client.mutate<VerifyMobileResponse>(
      VERIFY_MOBILE_AUTHENTICATION_TOKEN,
      { email, token }
    );
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | VERIFY_MOBILE_AUTHENTICATION_TOKEN mutation'
      );
      throw new Error(errors.message, { cause: errors });
    }
    return response.verifyMobile;
  };

  sendEmailLink = async (pendingJobUuid: string) => {
    const [response, errors] =
      await this.client.mutate<SendNotificationPendingJobResponse>(
        SEND_EMAIL_MAGIC_LINK,
        { pendingJobUuid }
      );
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | SEND_EMAIL_MAGIC_LINK mutation'
      );
      throw new Error('Unable to send magic link. Please try again.', {
        cause: errors
      });
    }
    return response.sendNotificationPendingJob.pendingJobUuid;
  };

  resetPassword = async (email: string) => {
    const [response, errors] =
      await this.client.mutate<SendPasswordResetResponse>(RESET_PASSWORD, {
        email
      });
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | RESET_PASSWORD mutation'
      );
      throw new Error('Failed to send password reset email', { cause: errors });
    }
    return response.sendPasswordReset.success;
  };

  logInWithPassword = async (userSession: LogInWithPasswordInput) => {
    const [response, errors] =
      await this.client.mutate<CreateUserSessionsResponse>(
        CREATE_USER_SESSIONS,
        { userSession }
      );
    if (errors) {
      DataDogRumAgent.addRumError(
        errors,
        'Oneflare | HOC withJobFormController | CREATE_USER_SESSIONS mutation'
      );

      if (errors instanceof GraphQLServerError) {
        // TODO: to be checked against code instead of string comparison
        if (errors.message.indexOf('Invalid email or password') > -1) {
          return null;
        }
      }

      // anything else
      throw new Error(errors.message, { cause: errors });
    }
    return response.createUserSessions.user.id;
  };
}

export default JobFormService;
