import { createContext } from 'react';
import { BehaviorSubject } from 'rxjs';

import { type VerifyMobileResponse } from 'mutations/shared/jobForm';
import { type SendMobileAuthenticationTokenResponse } from 'mutations/shared/mobileVerification';
import type { IJobFormController, JobFormState } from 'types/oneflare.com.au/jobForm';

const JobFormControllerContext = createContext<{
  controller: IJobFormController | null;
}>({
  controller: null
});

const intialJobFormControllerState =  (DOMAIN: 'wedding' | 'oneflare'): JobFormState => ({
  createPendingJobAttrs: null,
  currentStep: new BehaviorSubject(0),
  currentQuestionIdForTracking: undefined,
  currrentQuestionTextForTracking: undefined,
  jobFormCity: null,
  jobFormCategoryName: '',
  jobFormCategoryId: 0,
  jobFormPostcode: '',
  jobFormIniatedSection: '',
  jobFormInitiatedPage: '',
  jobFormInitiatedCtaText: '',
  firstName: null,
  fullName: null,
  phone: null,
  attachments: [],
  jobFormFailureListener$: new BehaviorSubject({ failed: false, message: null }),
  openJobForm$: new BehaviorSubject(false),
  isEmailLinkLoading: false,
  isEmailLinkSent$: new BehaviorSubject(false),
  showLoginWithPasswordStep$: new BehaviorSubject(false),
  resetPasswordEmailRequestSent$: new BehaviorSubject(false),
  isLoading$: new BehaviorSubject(false),
  isLoggedIn: new BehaviorSubject(false),
  pendingJobUuid$: new BehaviorSubject(null),
  isVerifyCodeLoading: false,
  resetPasswordEmailRequestLoading$: new BehaviorSubject(false),
  originalQuestions: new BehaviorSubject([]),
  userCreated: false,
  passwordError: null,
  questions$: new BehaviorSubject([]),
  sendMobileCodeData: {} as SendMobileAuthenticationTokenResponse['sendMobileAuthenticationToken'],
  currentUser: null,
  emailExists: false,
  phoneExists: false,
  overrideShowBackButton$: new BehaviorSubject(false),
  postedJob$: new BehaviorSubject(null),
  showSuccessScreen$: new BehaviorSubject(false),
  emailState: new BehaviorSubject({ errorMessage: '' }),
  mobileVerificationRequired: false,
  external: new BehaviorSubject({
    isIframe: DOMAIN === 'wedding',
    redirectOnSuccess: false,
    partnerLogo: DOMAIN === 'wedding' ? 'https://oneflare.s3.ap-southeast-2.amazonaws.com/static/wedding/logo.svg' : ''
  }),
  userAnswers: new BehaviorSubject({}),
  verifyMobileCodeData: {} as VerifyMobileResponse['verifyMobile'],
  marketingConsentConfirmed: false
});

export { JobFormControllerContext, intialJobFormControllerState };
