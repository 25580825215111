import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_USER, type GetCurrentUserResponse } from 'queries/shared/user';

const useCurrentUser = <T = GetCurrentUserResponse>(
  options?: QueryHookOptions<GetCurrentUserResponse & T>,
  defaultGetUserQuery = GET_USER
) => {
  const { data, loading, ...rest } = useQuery<T>(defaultGetUserQuery, {
    fetchPolicy: 'cache-first',
    ...options
  });

  const dataCurrentUser = data as GetCurrentUserResponse & T;

  return {
    loading,
    currentUser: dataCurrentUser?.currentUser,
    ...rest
  };
};

export { useCurrentUser };
