import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { JobFormFailureListener } from 'types/oneflare.com.au/jobForm';

import { SMSCodeError } from '../errors/smsCodeError';

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong processing your job request. Please try again in a few minutes or try refreshing your browser.';
export const UNKNOWN_ERROR_MESSAGE =
  'An unexpected error occurred. Please try again.';

export class JobFormErrorHandler {
  private jobFormFailureListener$: JobFormFailureListener;

  constructor(jobFormFailureListener$: JobFormFailureListener) {
    this.jobFormFailureListener$ = jobFormFailureListener$;
  }

  /**
   * Centralized error handling for job form operations
   * @param exception The error that occurred
   * @param context Optional context information to help with debugging
   */
  handleError(exception: Error, context?: string): void {
    this.resetError();

    if (context) {
      DataDogRumAgent.addRumError(exception, context);
    }

    switch (true) {
      case exception instanceof TypeError:
        this.jobFormFailureListener$.next({
          failed: true,
          message: DEFAULT_ERROR_MESSAGE
        });
        break;
      case exception instanceof SMSCodeError:
        this.jobFormFailureListener$.next({
          failed: true,
          message: DEFAULT_ERROR_MESSAGE
        });
        break;
      default: {
        // Use the original exception message or fallback to generic message
        const errorMessage = exception.message || UNKNOWN_ERROR_MESSAGE;
        this.jobFormFailureListener$.next({
          failed: true,
          message: errorMessage
        });
        break;
      }
    }
  }

  async wrapWithErrorHandling<T>({
    operation,
    context,
    onSuccess,
    onCatch
  }: {
    operation: () => Promise<T>,
    context: string,
    onSuccess?: (result: T) => void,
    onCatch?: () => void
  }): Promise<T | null> {
    try {
      const result = await operation();
      if (onSuccess) {
        onSuccess(result);
      }
      return result;
    } catch (exception) {
      this.handleError(exception, context);
      if (onCatch) {
        onCatch();
      }
      return null;
    }
  }

  /**
   * Reset error state
   */
  resetError(): void {
    this.jobFormFailureListener$.next({ failed: false });
  }
}
